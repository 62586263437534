/* Updated Home.css with specificity fixes */
/* Layout structure styles - designed to work with existing backgrounds */
/* Updated Text Animation CSS */
.rotating-text {
    text-align: center;
    font-weight: 700;
    padding-bottom: 10px;
    position: relative;
    margin-bottom: 30px;
  }
  
  .static-text {
    font-size: 3.5rem;
    color: #fff;
    margin: 0;
    line-height: 1.2;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
    display: inline-block;
  }
  
  .word-container {
    display: inline-block;
    position: relative;
    text-align: left;
    height: 70px; /* Fixed height */
    width: 300px; /* Fixed width - IMPORTANT */
    overflow: visible; /* Allow overflow to diagnose issues */
    vertical-align: top;
    margin-left: 15px;
  }
  
  .word-container p {
    margin: 0;
    display: inline-block;
    font-size: 3.5rem;
    color: #fff;
    font-weight: 700;
    position: relative;
    width: 100%;
  }
  
  .word {
    position: absolute;
    opacity: 0; /* Start invisible except for first word */
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1; /* Ensure proper stacking */
  }
  
  .word .letter {
    display: inline-block;
    position: relative;
    transform: translateZ(25px);
    transform-origin: 50% 50% 25px;
  }
  
  .word .letter.out {
    transform: rotateX(90deg);
    transition: transform 0.32s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  
  .word .letter.behind {
    transform: rotateX(-90deg);
  }
  
  .word .letter.in {
    transform: rotateX(0deg);
    transition: transform 0.38s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  
  /* Color variations for the rotating words */
  .primary-color {
    color: #fff;
  }
  
  .secondary-color {
    color: #f5f5f5;
  }
  
  .accent-color {
    color: #e6e6e6;
  }
  
  .highlight-color {
    color: #ffffff;
  }
  
  .emphasis-color {
    color: #f0f0f0;
  }
  
  /* Add perspective for 3D effect */
  .hero-text-container {
    margin-bottom: 3rem;
    perspective: 1000px;
  }
  
  /* Make animation work on mobile */
  @media screen and (max-width: 768px) {
    .static-text, .word-container p {
      font-size: 2.5rem;
    }
    
    .word-container {
      height: 55px;
      width: 250px;
    }
    .hero-text-container {
      margin-bottom: 0rem;
    }
  }
  
  @media screen and (max-width: 480px) {
    .static-text, .word-container p {
      font-size: 2rem;
    }
    
    .word-container {
      height: 45px;
      width: 200px;
    }
    
    .rotating-text {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    
    .word-container {
      margin-left: 0;
      margin-top: 10px;
    }
  }
.hero-slide-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    /* padding: 0 10%; */
    box-sizing: border-box;
    height: 100%;
    position: relative;
    z-index: 2;
}
  
.hero-content-wrapper {
    /* max-width: 800px; */
    /* margin: 0 auto; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 15px;
}
  
/* Map container styling */
.hero-map-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
  
.hero-map-tooltip {
    padding: 0.3rem 0.5rem;
    background: white;
    color: black;
    font-weight: bold;
    border-radius: 20px;
    position: absolute;
    z-index: 100;
    pointer-events: none;
    box-shadow: 0 2px 10px rgba(0,0,0,0.2);
}
  
/* New rotating text component styles with improved space handling */
.new-rotating-container {
  position: relative;
  margin-bottom: 2rem;
  text-align: center;
  perspective: 1000px;
}

.new-text-wrapper {
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping to new line */
  justify-content: center;
  /* align-items: baseline; This is key for text alignment */
  gap: 15px; /* Use gap instead of margin for better spacing */
}

.static-text-container {
  font-size: 5rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
  line-height: 1.2; /* Consistent line height */
}

.new-dynamic-container {
  position: relative;
  display: inline-block;
  height: auto; /* Let height be determined by content */
  min-width: 400px;
  text-align: left;
}

.new-words-wrapper {
  position: relative;
  width: 100%;
}

.new-rotating-word {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  font-size: 5rem;
  font-weight: 700;
  line-height: 1.2; /* Consistent line height */
  text-transform: uppercase;
  width: 100%;
  white-space: nowrap;
}

  /* Letter animation styles */
  .new-rotating-word .letter {
    display: inline-block;
    position: relative;
    transform: translateZ(25px);
    transform-origin: 50% 50% 25px;
  }
  
  .new-rotating-word .letter.space {
    display: inline-block;
    width: 0.5em; /* Adjust the space width */
  }
  
  .new-rotating-word .letter.out {
    transform: rotateX(90deg);
    transition: transform 0.32s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  
  .new-rotating-word .letter.behind {
    transform: rotateX(-90deg);
  }
  
  .new-rotating-word .letter.in {
    transform: rotateX(0deg);
    transition: transform 0.38s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  
  /* Space specific animations */
  .new-rotating-word .letter.space.out,
  .new-rotating-word .letter.space.behind,
  .new-rotating-word .letter.space.in {
    /* Keep spaces visible during animations */
    opacity: 1 !important;
  }
  
  /* Color variations */
  .new-color-primary {
    color: #fff;
  }
  
  .new-color-secondary {
    color: #f5f5f5;
  }
  
  .new-color-accent {
    color: #e6e6e6;
  }
  
  .new-color-highlight {
    color: #ffffff;
  }
  
  .new-color-emphasis {
    color: #f0f0f0;
  }
  
  /* Responsive adjustments */
  @media screen and (max-width: 992px) {
    .new-static-text,
    .new-rotating-word {
      font-size: 3rem;
    }
    
    .new-dynamic-container {
      height: 60px;
      min-width: 350px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .new-static-text,
    .new-rotating-word,.static-text-container {
      font-size: 3.5rem;
      font-weight: 700;
      text-align: center;
    }
    .mobile_new{ 
      font-size: 2.5rem !important;
    }
    .new-text-wrapper {
    gap: 0px;
    }
    .new-dynamic-container {
      height: 55px;
      min-width: 300px;
    }
    
    /* Stack on smaller screens if needed */
    .new-text-wrapper {
      flex-direction: column;
    }
    
    .new-static-text {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
  
 
/* Vertical navigation enhancements */
.vertical-nav {
    position: absolute;
    left: 10rem;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
}
  
.vertical-nav .nav-dot {
    width: 8px;
    height: 20px;
    border-radius: 30px;
    background-color: white;
    margin: 10px 0;
    opacity: 0.5;
    cursor: pointer;
    transition: all 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
  
.vertical-nav .nav-dot.active {
    opacity: 1;
    height: 60px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}
  
.vertical-nav .nav-dot:not(.active):hover {
    opacity: 0.8;
    height: 45px;
    transform: scale(1.1);
}
  
/* Ensure container heights are appropriate */
.home-component .home_container {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    z-index: 5;
}
  
/* Responsive adjustments for layout */
@media screen and (max-width: 1200px) {
    .vertical-nav {
        left: 5rem;
    }
    
    .hero-slide-container {
        padding: 0 5%;
    }
}
  
@media screen and (max-width: 768px) {
    .vertical-nav {
        left: 2rem;
    }
    
    .hero-slide-container {
        padding: 40% 3%;
    }
    .letter-image {
      display: none;
    }
    
    .hero-content-wrapper {
        padding: 0 10px;
    }
}
  
@media screen and (max-width: 480px) {
    .vertical-nav {
        left: 1rem;
    }
    
    .vertical-nav .nav-dot.active {
        height: 40px;
    }
    
    .vertical-nav .nav-dot {
        height: 10px;
        margin: 6px 0;
    }
}
  
  
.hero-main-title {
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 1.2;
    /* margin-bottom: 1rem; */
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
}
  
.hero-subtitle {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 2rem;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
}
  
.hero-description {
    font-size: 1rem;
    line-height: 1.6;
    font-weight: 500;
    color: #fff;
    text-align: center;
    max-width: 700px;
    margin: 0 auto;
    opacity: 0.9;
}
  
.hero-button-wrapper {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
}
  
.hero-action-button {
    font-family: Poppins;
font-weight: 600;
/* font-size: 18px; */
line-height: 9px;
letter-spacing: 1%;
    background-color: white;
    color: #013A4E;
    border: none;
    border-radius: 50px;
    padding: 15px 85px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-transform: capitalize;
}
  
.hero-action-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}
  
.hero-right-section {
    /* This replaces right_container */
    display: none; /* Since it appears empty in the design */
}

/* ROTATING TEXT ANIMATION STYLES - ADD THIS TO FIX THE ANIMATION */
.rotating-text {
    text-align: center;
    font-weight: 700;
    padding-bottom: 10px;
    position: relative;
}
  
.static-text {
    font-size: 3.5rem;
    color: #fff;
    margin: 0;
    line-height: 1.2;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: center;
}
  
.word-container {
    display: inline-block;
    position: relative;
    text-align: left;
    height: 70px; /* Adjust based on your font size */
    overflow: hidden;
    vertical-align: top;
    margin-left: 15px;
}
  
.word-container p {
    margin: 0;
    display: inline-block;
    font-size: 3.5rem;
    color: #fff;
    font-weight: 700;
    position: relative;
}
  
.word {
    position: absolute;
    opacity: 0;
    width: 100%;
    top: 0;
    left: 0;
    font-size: 3.5rem;
    line-height: 1.2;
}
  
.word .letter {
    display: inline-block;
    position: relative;
    transform: translateZ(25px);
    transform-origin: 50% 50% 25px;
}
  
.word .letter.out {
    transform: rotateX(90deg);
    transition: transform 0.32s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
  
.word .letter.behind {
    transform: rotateX(-90deg);
}
  
.word .letter.in {
    transform: rotateX(0deg);
    transition: transform 0.38s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
  
/* Color variations for the rotating words */
.primary-color {
    color: #fff;
}
  
.secondary-color {
    color: #f5f5f5;
}
  
.accent-color {
    color: #e6e6e6;
}
  
.highlight-color {
    color: #ffffff;
}
  
.emphasis-color {
    color: #f0f0f0;
}
/* END OF ROTATING TEXT ANIMATION STYLES */
  
/* Responsive styles with unique class names */
@media screen and (max-width: 768px) {
    .hero-main-title {
        font-size: 2.5rem;
    }
    
    .hero-subtitle {
        font-size: 1.8rem;
    }
    
    .hero-description {
        font-size: 0.9rem;
    }
    
    .static-text, .word-container p {
        font-size: 2.5rem;
    }
    
    .word-container {
        height: 55px;
    }
}
  
@media screen and (max-width: 480px) {
    .hero-main-title {
        font-size: 2rem;
    }
    
    .hero-subtitle {
        font-size: 1.5rem;
    }
    
    .hero-content-wrapper {
        padding: 0 10px;
    }
    
    .static-text, .word-container p {
        font-size: 2rem;
    }
    
    .word-container {
        height: 45px;
    }
}

/* Fixed position envelope animation - bottom right with lighter colors */
.letter-image {
    position: fixed; /* Fixed position to stay in place */
    bottom: 15vh; /* Position at the bottom */
    right: 10rem; /* Padding from right as requested */
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 1000; /* Stay above all content */
}
  
.animated-mail {
    position: absolute;
    height: 75px;
    width: 100px;
    transition: .4s;
}
  
.animated-mail .body {
    position: absolute;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 50px 100px;
    border-color: transparent transparent #0a5470 transparent; /* Lighter variation */
    z-index: 2;
}

.animated-mail .letter-text {
    top: 20px;
    padding-left:5px;
    text-align: center;
    padding-top: 5px;
    font-size: 0.7rem;
    font-weight: bold;
    color: #013A4E;
}

.animated-mail .top-fold {
    position: absolute;
    top: 25px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 25px 50px 0 50px;
    transform-origin: 50% 0%;
    transition: transform .4s .4s, z-index .2s .4s;
    border-color: #013A4E transparent transparent transparent; /* Original dark color for contrast */
    z-index: 2;
}
  
.animated-mail .back-fold {
    position: absolute;
    bottom: 0;
    width: 100px;
    height: 50px;
    background: #013A4E; /* Original dark color */
    z-index: 0;
}
  
.animated-mail .left-fold {
    position: absolute;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 25px 0 25px 50px;
    border-color: transparent transparent transparent #1369a1; /* Even lighter for visibility */
    z-index: 2;
}
  
.animated-mail .letter {
    left: 10px;
    bottom: 0px;
    position: absolute;
    width: 80px;
    height: 30px;
    background: white;
    z-index: 1;
    overflow: hidden;
    transition: .4s .2s;
}
  
.animated-mail .letter .letter-border {
    height: 5px;
    width: 100%;
    background: repeating-linear-gradient(
        -45deg,
        #0a5470, /* Lighter blue */
        #0a5470 4px,
        transparent 4px,
        transparent 9px
    );
}


  
.animated-mail .letter .letter-stamp {
    /* margin-top: 15px; */
    margin-left: 60px;
    border-radius: 100%;
    height: 15px;
    width: 15px;
    background: #013A4E;
    opacity: 1; /* Increased from 0.3 for better visibility */
}
  
.shadow {
    position: absolute;
    top: 100px;
    left: 50%;
    width: 200px;
    height: 15px;
    transition: .4s;
    transform: translateX(-50%);
    border-radius: 100%;
    background: radial-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.0), rgba(0,0,0,0.0));
}
  
/* Hover animation */
.letter-image:hover .animated-mail {
    transform: translateY(25px);
}
  
.letter-image:hover .animated-mail .top-fold {
    transition: transform .4s, z-index .2s;
    transform: rotateX(180deg);
    z-index: 0;
}
  
.letter-image:hover .animated-mail .letter {
    height: 90px;
}
  
.letter-image:hover .shadow {
    width: 125px;
}
  
/* Add a subtle glow effect for better visibility */
.letter-image::after {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border-radius: 50%;
    background: radial-gradient(
        circle at center,
        rgba(19, 105, 161, 0.2) 0%,
        rgba(19, 105, 161, 0) 70%
    );
    z-index: -1;
    pointer-events: none;
}

/* Use more specific selectors to ensure our styles take precedence */
.home-component .home_container {
    display: flex;
    flex-direction: row;
    height: 90vh; /* Full viewport height */
    /* width: 100%; */
    position: relative;
    overflow: hidden;
}
  
.home-component .container_one {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    /* padding: 12vh; */
    box-sizing: border-box;
    height: 100%;
    background-color: transparent;
}
  
.home-component .right_container {
    width: 50%;
    height: 100%;
    background-size: cover;
    background-position: center;
}
  
/* Brand and text styles with higher specificity */
.home-component .brand_name {
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 1.2;
    /* margin-bottom: 1rem; */
    color: #fff;
    padding: 0;
}
  
.home-component .home_subheader {
    font-size: 1.8rem;
    font-weight: 400;
    /* margin-bottom: 1.5rem; */
    color: #fff;
}
  
.home-component .brand_name_description {
    font-size: 1.2rem;
    line-height: 1.6;
    max-width: 600px;
    color: #fff;
    padding: 0;
}
  
/* Button and navigation styles */
.home-component .my_button.homeb {
    display: inline-block;
    padding: 0.8rem 2rem;
    background-color: white;
    color: #013A4E;
    border-radius: 50px;
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
  
/* ---------------------------- */
.home-component .my_button.homeb:hover {
    background-color: #f5f5f5;
    transform: translateY(-2px);
}
  
.home-component .home_readmore, 
.home-component .home_readmore_tablet {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: auto;
}
  
.home-component .rotating_dots {
    display: flex;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
  
/* Carousel structure with more specific selectors */
.home-component .carousel-container {
    position: relative;
    width: 100%;
    height: 83.5vh;
    overflow: hidden;
    box-shadow: none;
    min-height: unset;
    max-height: unset;
    /* Add this to make sure there's no overflow */
    padding: 0;
    margin: 0;
  }
  /* Make sure home_container doesn't add padding either */
/* Make sure the container for slide content doesn't cause any shrinking */
.home-component .carousel-slide .home_container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    /* Prevent any size adjustments that could cause shrinking */
    transform: translateZ(0);
  }
  
  .home-component .carousel-slide:nth-child(1),
  .home-component .carousel-slide:nth-child(2),
  .home-component .carousel-slide:nth-child(3) {
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
  }
  
  .home-component .carousel-slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden; /* Hide it completely when not active */
    transition: opacity 1s ease-in-out, visibility 1s ease-in-out;
    background-color: #013A4E;
    /* Use transform: translateZ(0) to force hardware acceleration */
    transform: translateZ(0);
    will-change: opacity; /* Hint to browser about what will change */
  }
  
  .home-component .carousel-slide:nth-child(1) {
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.45)), url('./img/asd.webp');
    background-size: contain;
    /* background-color: white; */
    background-position: top; /* 👈 Start image from the top */
    background-repeat: no-repeat;
  }
  
  /* Hero */
.home-component .carousel-slide:nth-child(2) {
  /* background:  url('./img/bkc.webp'); */
  background-color:  #dedede;
    background-size: cover;
    /* background: linear-gradient(180deg, white 0%, rgba(1, 58, 78, 0.7) 100%); */

    /* filter: drop-shadow(0px 15px 20px rgba(30, 30, 30, 0.1)); */
    background-position: center;
}
  
.home-component .carousel-slide:nth-child(3) {
  background: linear-gradient(rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.4)), url('./img/meet-the-team.png');
  background-size: cover;
    background-color: black;
    background-position: center;
}
.home-component .carousel-slide:nth-child(4) {
  /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url('./img/meet-the-team.png'); */
  background-size: cover;
  background-color: #dedede;
  background-position: center;
}
.home-component .carousel-slide-active {
    opacity: 1;
    visibility: visible;
    z-index: 1;
  }
  
  
/* Navigation dots with enhanced animations */
.home-component .vertical-nav {
    position: absolute;
    left: 10rem;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
}
  
.home-component .vertical-nav .nav-dot {
    width: 8px;
    height: 15px;
    border-radius: 30px;
    background-color: white;
    margin: 10px 0;
    opacity: 0.5;
    cursor: pointer;
    /* Enhanced transitions with multiple properties */
    transition: all 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
  
.home-component .vertical-nav .nav-dot.active {
    opacity: 1;
    height: 60px;
    border-radius: 10px;
    /* Add a subtle shadow for more depth when active */
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}
  
/* Hover effect for non-active dots */
.home-component .vertical-nav .nav-dot:not(.active):hover {
    opacity: 0.8;
    height: 25px;
    transform: scale(1.1);
}
  
/* Contact button with specificity */
.home-component .contact-us-button {
    position: absolute;
    right: 30px;
    bottom: 30px;
    z-index: 10;
    background-color: white;
    padding: 10px 20px;
    border-radius: 50px;
    font-weight: 600;
    cursor: pointer;
}
  
/* Chat button with specificity */
.home-component .chat-button {
    position: absolute;
    right: 150px;
    bottom: 30px;
    z-index: 10;
    background-color: transparent;
    border: 2px solid white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
  
/* Tooltip styles */
.home-component .tooltip {
    padding: 0.3rem 0.5rem;
    background: white;
    color: black;
    font-weight: bold;
    border-radius: 20px;
    position: absolute;
    z-index: 100;
    pointer-events: none;
}
  
/* Responsive styles with more specific selectors */
@media screen and (max-width: 992px) {
    .home-component .container_one {
        width: 70%;
    }
    
    .home-component .right_container {
        width: 30%;
    }
    
    .home-component .brand_name {
        font-size: 2.8rem;
    }
    
    .home-component .home_subheader {
        font-size: 1.5rem;
    }
}
  
@media screen and (max-width: 768px) {
    .home-component .home_container {
        flex-direction: column;
        height: 100vh;
        padding-top: 12rem;
    }
    
    .home-component .carousel-slide:nth-child(2) {
      position: relative;
      z-index: 1;
      overflow: hidden;
    }
    
    .home-component .carousel-slide:nth-child(2)::before {
      content: "";
      position: absolute;
      inset: 0; /* shorthand for top: 0; right: 0; bottom: 0; left: 0; */
      background: url('./img/map-img.png') center/cover no-repeat;
      opacity: 0.6; /* 👈 Only the image has opacity */
      z-index: -1;
    }
    
    .home-component .container_one {
        width: 100%;
        padding: 10vh 5% 6vh 5%;
    }
    
    .home-component .right_container {
        display: none;
    }
    
    .home-component .mobile_hide {
        display: none;
    }
    
    .home-component .small_screen_show {
        display: flex;
    }
    
    .home-component .brand_name {
        font-size: 2.2rem;
        padding: 0;
    }

    .contact-form-section {
      padding: 0rem;
    }
    
    .home-component .vertical-nav {
        left: 15px;
    }
}
  
@media screen and (max-width: 480px) {
    .home-component .container_one {
        padding: 8vh 5% 4vh 5%;
    }
    
    .home-component .brand_name {
        font-size: 1.8rem;
    }
    
    .home-component .home_subheader {
        font-size: 1.2rem;
    }
    
    .home-component .brand_name_description {
        font-size: 1rem;
    }
    
    .home-component .contact-us-button {
        right: 15px;
        bottom: 15px;
        padding: 8px 15px;
    }
    
    .home-component .chat-button {
        right: 100px;
        bottom: 15px;
    }
}

/* Main container with flexbox for the split layout */
.contact-main-container {
    display: flex;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
  }
  
  /* Form section - Left half */
  .contact-form-section {
    width: 50%;
    /* background:#013A4E; */
    /* background: url('./img/contact-us.jpeg'); */
    background-size: cover;
    background-position: center;
    position: relative;
    padding: 5rem 0rem 0rem 0rem;
    display: flex;
    flex-direction: column;
    justify-content: start;
  }
  
  /* Semi-transparent overlay for better readability */
  .contact-form-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 0;
  }
  
  .contact-form-wrapper {
    position: relative;
    z-index: 1;
    margin-top: 2rem;
    max-width: 600px;
    /* margin: 0 auto; */
    width: 100%;
  }
  
  .contact-heading {
    color: #013A4E;
    margin-bottom: 2rem;
    font-size: 2rem;
    text-align: left;
  }
  
  /* Row container */
  .contact-row {
    margin-bottom: 1.5rem;
    width: 100%;
  }
  
  /* Special handling for the name/surname row */
  .contact-name-row {
    display: flex;
    gap: 1rem;
  }
  
  /* Textfield styling */
  .contact-textfield {
    width: 100%;
  }
  
  .contact-full-width {
    width: 100% !important;
  }
  
  /* Submit button */
  .contact-submit-button {
    background-color: #013A4E;
    color: white;
    border: none;
    border-radius: 25px;
    padding: 0.75rem 2rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .contact-submit-button:hover {
    background-color: #025a7a;
  }
  
  /* Contact information container */
  .contact-info-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
  }
  
  .contact-info-item {
    display: flex;
    align-items: center;
  }
  
  .contact-icon {
    color: #013A4E;
    margin-right: 0.5rem;
  }
  
  .contact-text {
    color: #013A4E;
    font-size: 1rem;
  }
  
  /* Map section - Right half */
  .contact-map-section {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0rem;
    justify-content: start;
    /* background-color: #f5f5f5; Fallback background if map doesn't fill space */
  }
  
  /* Responsive adjustments for smaller screens */
  @media (max-width: 992px) {
    .contact-main-container {
      flex-direction: column;
    }
    
    .contact-form-section,
    .contact-map-section {
      width: 100%;
      min-height: 50vh;
    }
    

    .contact-form-wrapper {
      margin-top: 0rem;
    }
    .contact-form-section {
      /* padding: 2rem; */
    }
    
    .contact-name-row {
      flex-direction: column;
      gap: 1.5rem;
    }
  }

  .view-wrapper {
    position: relative;
    width: 100%;
    height: 100vh; /* or however tall your view should be */
  }
  .hero-slide-container-map {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center; /* vertical centering */
    justify-content: center; /* horizontal centering if needed */
    pointer-events: none; /* optional */
    display: flex ;
        flex-direction: column;
        justify-content: center;
    
  }

  .map-text {
    color: #013A4E !important;
  }

  .employee_bck {
    background: linear-gradient(180deg, #D3D3D3 0%, rgba(1, 58, 78, 0.7) 100%);

  }