/* NewsDetail.css */
.article-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem 1rem 4rem;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  .back-to-news {
    display: inline-flex;
    align-items: center;
    color: rgb(1, 58, 78);
    font-weight: 600;
    text-decoration: none;
    margin-bottom: 2rem;
    transition: color 0.2s ease;
    font-size: 1rem;
  }
  
  .back-to-news:hover {
    color: rgba(1, 58, 78,0.7);
    text-decoration: underline;
  }
  
  .article-content {
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  }
  
  .article-header {
    padding: 2rem 2rem 1.5rem;
  }
  
  .article-title {
    font-size: 2.5rem;
    font-weight: 800;
    color: #1a202c;
    margin-bottom: 0.75rem;
    line-height: 1.2;
  }
  
  .article-subtitle {
    font-size: 1.5rem;
    font-weight: 500;
    color: #4a5568;
    margin-bottom: 1.5rem;
    line-height: 1.4;
  }
  
  .article-meta {
    color: #718096;
    font-size: 0.9375rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e2e8f0;
  }
  
  .article-featured-image {
    width: 100%;
    margin-bottom: 2rem;
  }
  
  .article-featured-image img {
    width: 100%;
    max-height: 500px;
    object-fit: cover;
  }
  
  .article-body {
    padding: 0 2rem 2rem;
    color: #2d3748;
    line-height: 1.8;
    font-size: 1.125rem;
  }
  
  .article-text-section {
    margin-bottom: 2rem;
  }
  
  .article-text-section h1,
  .article-text-section h2,
  .article-text-section h3,
  .article-text-section h4,
  .article-text-section h5,
  .article-text-section h6 {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    color: #1a202c;
    font-weight: 700;
    line-height: 1.3;
  }
  
  .article-text-section h1 {
    font-size: 2rem;
  }
  
  .article-text-section h2 {
    font-size: 1.75rem;
  }
  
  .article-text-section h3 {
    font-size: 1.5rem;
  }
  
  .article-text-section p {
    margin-bottom: 1.25rem;
  }
  
  .article-text-section a {
    color: #4f46e5;
    text-decoration: underline;
    transition: color 0.2s ease;
  }
  
  .article-text-section a:hover {
    color: #4338ca;
  }
  
  .article-text-section ul,
  .article-text-section ol {
    margin-bottom: 1.25rem;
    padding-left: 1.5rem;
  }
  
  .article-text-section li {
    margin-bottom: 0.5rem;
  }
  
  .article-text-section blockquote {
    margin: 1.5rem 0;
    padding: 1rem 1.5rem;
    border-left: 4px solid #4f46e5;
    background-color: #f7fafc;
    font-style: italic;
    color: #4a5568;
  }
  
  .article-image-section {
    margin: 2rem 0;
    text-align: center;
  }
  
  .article-image-section img {
    max-width: 100%;
    border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .article-image-section figcaption {
    margin-top: 0.75rem;
    font-size: 0.875rem;
    color: #718096;
    font-style: italic;
  }
  
  .article-footer {
    margin-top: 3rem;
    text-align: center;
  }
  
  .article-loading,
  .article-error,
  .article-not-found {
    text-align: center;
    padding: 3rem 1rem;
    margin: 2rem auto;
    max-width: 600px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  }
  
  .article-loading {
    font-size: 1.125rem;
    color: #4a5568;
  }
  
  .article-error {
    color: #e53e3e;
  }
  
  .article-error h2,
  .article-not-found h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #1a202c;
  }
  
  .article-error p,
  .article-not-found p {
    margin-bottom: 1.5rem;
    color: #4a5568;
  }
  
  @media (max-width: 768px) {
    .article-container {
      padding: 1.5rem 1rem 3rem;
    }
    
    .article-header {
      padding: 1.5rem 1.5rem 1rem;
    }
    
    .article-body {
      padding: 0 1.5rem 1.5rem;
      font-size: 1rem;
    }
    
    .article-title {
      font-size: 2rem;
    }
    
    .article-subtitle {
      font-size: 1.25rem;
    }
  }
  
  @media (max-width: 480px) {
    .article-header {
      padding: 1.25rem 1.25rem 0.75rem;
    }
    
    .article-body {
      padding: 0 1.25rem 1.25rem;
    }
    
    .article-title {
      font-size: 1.75rem;
    }
    
    .article-subtitle {
      font-size: 1.125rem;
    }
    
    .article-text-section h1 {
      font-size: 1.5rem;
    }
    
    .article-text-section h2 {
      font-size: 1.375rem;
    }
    
    .article-text-section h3 {
      font-size: 1.25rem;
    }
  }