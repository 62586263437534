/* App.css */

body {
  margin: 0;
  padding: 0;
  color: #2c3639;
  overflow: hidden; /* Prevent scrolling */

}

.App {
  text-align: center;
}

.employee_card {
  user-select: none;
  padding: 3.5rem 1rem 1rem 1rem;
  max-width: 18rem;
  border-radius: 20px;
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* gap: 1rem; */
}

.employee_card:hover {
  cursor: pointer;
}
.employee_name {
  margin-top: 1rem;
  font-size: 27px;
  font-weight: 700;
  padding: 10px 0px 0px 0px;
  margin: 0px;
  /* font-family: 'Poppins'; */
}
.employee_position {
  font-weight: 400;
  font-size: 20px;
  padding: 0px;
  margin: 0px;
}
#welcome:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://s3-alpha-sig.figma.com/img/59a8/0e36/2e7feec849d74296e5b9b045bf855351?Expires=1734912000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=A-m2x76-TQMHqIEwUBjuxdSJNN1ct3zLJA-PqgJuNSsIUj-Z7PWoFDz~EMNU58~OxvtP7~clLKCd5vEcbDW5UQ5ge9U5dBfc1ibJtvtra05P1jeib0dDVwRrjcr-MsPeDm4ohQHwNeq5wbRjwVnH3OATEJv3ErxKiJowNU9YZ6EYQRlboJVz-8YqyyRwN3ylpjWhcHNRrjraveKiZ1FHV1b~MHh-PTpODGweVx~EY~b75v-Tr~rRQLJFmosvhBVwXKddhhLVJoztc64paRNoFrn~1CQM0jbXfPug85LS44NBL3YfTfHr4OottDgC2sRX4mucCEMitqLnPHhzpqZ~Gg__");
  background-size: cover;
  background-position: center;
  background-blend-mode: overlay; /* Blend the black color with the image */
  z-index: -1; /* Ensure it is behind the content */
  /* background-color: rgba(0, 0, 0, 0.7);  */

}




#third_view:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://dotalive.org/wp-content/uploads/2024/12/DALL·E-2024-12-11-23.40.16-A-professional-16_9-ratio-image-representing-a-law-office-firm.-The-scene-features-a-clean-and-modern-office-space-with-a-polished-wooden-desk-neatly.webp");
  background-size: cover;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.7); /* Black overlay with 50% opacity */
  background-blend-mode: overlay; /* Blend the black color with the image */
  z-index: -1; /* Ensure it is behind the content */
}



.home_subheader {
  color: #013A4E;
  font-size: 32px;
font-weight: 400;
}


.Skopje {
  fill: #013a4e;
  transition: fill 0.3s ease;
  /* animation: wave-animation-50 1.5s infinite ease-in-out; */
  /* transform-origin: center; */
}
.circle_1 {
  fill: #013a4e3d;
  stroke: none;
  transition: fill 0.3s ease;
  /* animation: wave-animation-50 1.5s infinite ease-in-out; */
  /* transform-origin: center; */
}
.circle_2 {
  fill: #013a4e75;
  stroke: none;
  transition: fill 0.3s ease;
  /* animation: wave-animation-50 1.5s infinite ease-in-out; */
  /* transform-origin: center; */
}
.circle_3 {
  fill: #013a4e;
  stroke: none;
  transition: fill 0.3s ease;
  /* animation: wave-animation-50 1.5s infinite ease-in-out; */
  /* transform-origin: center; */
}
.circle_3:hover,
.circle_1:hover,
.circle_2:hover {
  cursor: pointer;
}

/* Individual droplets */
.waterDroplet {
  fill: #013a4e;
  transform-origin: center bottom;
  animation: waterDrop 1.5s infinite ease-in-out;
}
#second_view {
  background-image: url("https://www.armstrongwatson.co.uk/sites/armstrongwatson.co.uk/files/news-images/2023/law_firm_sale.jpg");
  background-size: cover;
  background-position: center;
  /* Adjust the opacity as needed */
  z-index: -1;
  /* padding: 0rem 10rem; */
}

#welcome_second {
  /* background-image: url('https://lh3.googleusercontent.com/fife/APg5EOZjx9ueyKN-dYq569jw2hg3Zx3md9L3xHecFZV6QowStERXjI63Yw0WlS9Uqv8t5Q_OKdfslOgjg-BR894-tSYsmT7rEeT4PLNJ2RLjhiyVw3WnQKxmRLYZnBHcwS4mgzcP_16MymoZIo7vVtSIaA67Zmuu3fK5hspVQ6UsOs-fs-dJCVldr8w08-55osJzAm68fORa8JqTW3PDdp-gU1oGM5OhoaXpTpS7GmE9OzKhKmS7JPyFstydd6F7SnqfzDk28syJzblUQuDTDCZ6coF4-jVDg9XB_AkcoLfOFJRFJRiVURxrr3EPC_L4L5TGbpy6XoffAi60a102CE-tWVCizX9BTa0QhX17heuZCsSOz2w44mmNS1L--DZ9HpTvf0aVt8MDbnoiBazAtuR0NfjZtWpi8VAcPTE5zdo8NFwU2qu2fXHeBquR2yzZEIWXEOu7EaNSO56OeDxo2Q4lYinaVXEie1EuyTSZpiTr7lz_qc86uN7McSf_c_ubp3OFV1kbNocdLNKBECeczEcpfY5Dii29Lmt17XLGe04WEJpxVZ2a4R_0N6WK0sB68gJD10je-fzmfu-5PzLJZoKIaBpOfYbvuDaE83TweLUD_cKOy_plroNYoUz-m7dGGIX2uBzpMCw54MqiFVpmVRjseN7gyDgw-nlUXkF5JFWpXn3z1ofU2nWPHS3I0CeXExHdqVR9szYiOCxpg3eIn-KZe144icwwngTSGpiA5TtmTp2O4oyx1C7aA6UUTJLcIsaPhEvLUSCPFshAl_umhaBkePhNGtvqLN2Q7iJuijr8-9xA0bEdpiGbTwPbk0EbF0qQ37VByZ-0nMCM8eWf3T8OWs87UY0Cul-K3xSRtyozBq9vbHkVwvGPICQfVc9dc1X9pgI0l_56Rq54-doehSyEbC65NPYy6JOI8bE9ikeep9tJDWDChoGXoTXRSkAVkCHgI3MiP9cvwKP8niqWzQRVZ93_NTPOAWf8bCDchpcqMtRMJStHKqY7z99_3OlM3ece9ShNFbqtToq45UxLyRVedmgk3EupS-YXbqAZbMzSMZPbe4yp8AZx7tbpdEvsJmd7P5jHEIGzRjpPL2yhVPWFyq6WMiP1pD2WmMOskAaYPQBbIX9rXU668EWfCmIGzjWA-AkPrFZBTQaQNB-ljTRaNJtK8wpU7s-QMilNRWv0V26BJSPfSpKp1HsX4F3uizdr1uFr_ubrNlm2LS7-Hb0AQSGW-uuZC6D6EwGsnqHPtpfPnJkBml9lBxqqj6dVy0I14_ieMgGOoaQAV8q09YBs2s2lNhNvhrfslw9yRjZ0nqv9ERbSsBeKU3xerUzCYOEPB16xfvIn3AjSDkA2iaNHbQEkBKVxKTPnRPZx8XApdkXoLKLUBc91NNGtbkPSVurkrn4ySrh-MHPOeT5BmyrIWySKZ7tYDzNN6hAG1ZjuWw6KRRgEl6uXRrvt10OFx-Ki8wZv504lEmheRuw0tVdEgNKSBPxP_rSYMyiT0lgKsL5usCts6NfpcIXziueBTQsxLY0GSw=w1242-h977'); */
  background-size: cover;
  background-position: center;
  display: flex;
  background-color: #f6f4f2;
  /* Adjust the opacity as needed */
  z-index: -1;
  /* padding: 0rem 10rem; */
}

.extra_info {
  display: block;
  padding: 1rem;
  text-align: start;
}

#equipment {
  max-width: 82%;
  margin: auto;
  margin-bottom: 4rem;
}

#equipment img {
  max-height: 30vh;
}
.mobile_screen_show {
  display: none !important;
}
.heading_buttom_line {
  background: #013A4E;
  min-height: 2px;
  margin: 0.3rem 0rem 0rem 0rem;
  width: 5.5rem;
}

#about_us {
padding: 1.5rem 10rem 0rem 10rem ;
overflow: scroll;
  /* background-color: #000103; */
  /* margin-bottom: 0.3rem; */
  /* padding: 5rem 10rem; */
  /* box-shadow: 0px 15px 20px 0px #1e1e1e1a; */
  /* max-height: 75vh; */
}

#our_team {
  padding: 1.5rem 10rem 0rem 10rem ;
  color: white;
    /* background-color: #000103; */
    /* margin-bottom: 0.3rem; */
    /* padding: 5rem 10rem; */
    box-shadow: 0px 15px 20px 0px #1e1e1e1a;
    /* min-height: 220vh; */
    /* overflow-y: scroll; */
    /* padding-bottom: 10rem; */
  }
#contant_form {
  padding: 1.5rem 10rem 0rem 10rem ;
    /* background-color: #000103; */
    /* margin-bottom: 0.3rem; */
    /* padding: 5rem 10rem; */
    box-shadow: 0px 15px 20px 0px #1e1e1e1a;
    max-height: 70vh;
    overflow-y: scroll;
  }
#details {
  box-shadow: 0px 15px 20px 0px #1e1e1e1a;
  /* max-height: 75vh; */
  /* overflow-y: scroll; */
}

#prices_container,
#services,
#news_container,
#current_news {
  /* margin: auto; */
  /* max-width: 82%; */
}

.about-us-prod-header {
  font-style: normal;
  font-weight: 700;
  text-align: start;
  font-size: 33px;
  line-height: 36px;
  letter-spacing: 0.03em;
  color: #fff;
}

.about-us-prod-info {
  text-align: start;
  font-style: normal;
  font-weight: 300;
  font-size: x-large;
  line-height: 30px;
  letter-spacing: 0.03em;
  color: #fff;
}

.mobile_show {
  display: none;
}

.about-us-prod-header-services {
  font-style: normal;
  font-weight: 700;
  text-align: start;
  font-size: 33px;
  line-height: 36px;
  letter-spacing: 0.03em;
  color: #000103;
}

.about-us-prod-info-services {
  text-align: start;
  font-style: normal;
  font-weight: 300;
  font-size: x-large;
  line-height: 30px;
  letter-spacing: 0.03em;
  color: #000103;
}

.about_us_intro {
  padding: 20px 0px;
  display: flex;
  width: 100%;
}

.about_us_header {
  padding: 20px;
  width: 100%;
}

.about_us_bottom {
  display: flex;
  width: 100%;
  padding-bottom: 1rem;
}

.homepage_map {
  width: 60%;
}

.about_us_top {
  display: flex;
  width: 100%;
}

.about_us_top_left {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* margin-top: 10vh; */
  padding: 20px;
}

.about_us_buttom_right {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 10vh;
  padding: 20px;
}

.brand_name {
  color: #013A4E;
  /* padding-top: 25vh; */
  /* font-size: 60px; */
  font-size: 3.5rem;
  font-weight: 500;
  /* line-height: 8rem; */
  /* letter-spacing: 0.05em; */
  text-align: left;
}

.brand_name_contact {
  color: #013A4E;
  text-align: left;
  font-size: clamp(24px, 5vw, 36px); /* Minimum 24px, maximum 32px, dynamic based on viewport width */
  line-height: 1.5; /* Maintain consistent line spacing */

}


.carousel-slide {
  position: absolute;
  height: 100%; /* Make slides fill the container */

  /* Start offscreen */
  transition: transform 0.5s ease-in-out;
  /* Smooth sliding transition */
}

.carousel-slide-active {
  transform: translateX(-100%);
  /* Slide into view */
}

.brand_name_subtitle {
    color: rgb(1, 58, 78);
    font-weight: 400;
    margin-top: 1rem;
    /* padding-top: 1rem; */
    font-size: clamp(12px, 2.5vw, 15px); /* Responsive font size */
    line-height: 1.5;
    max-height: 100px; /* Set a max height for the text */
    overflow-y: auto; /* Enable vertical scrolling if the content exceeds the max height */
    padding-right: 10px; /* Optional: Add some padding for better readability */
    max-height: 55%;
    min-height: 25%;
  }

  /* Optional: Style the scroll bar for better aesthetics */
  .brand_name_subtitle::-webkit-scrollbar {
    width: 6px;
  }

  .brand_name_subtitle::-webkit-scrollbar-thumb {
    background: rgb(1, 58, 78);
    border-radius: 3px;
  }

  .brand_name_subtitle::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

.brand_name_button {
  /* padding-top: 5vh; */
}

.brand_name_description {
  padding-bottom: 3vh;
  color: #013A4E;
  font-weight: 400;
   font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5; /* Adjusted for better readability */
  text-align: left;

  /* Prevent text from going too wide */
  max-width: 75ch; /* Limits the width to approximately 60 characters */
  word-wrap: break-word; /* Ensures long words are broken onto the next line if needed */
  overflow-wrap: break-word; /* Modern alternative for breaking long words */
}


.welcome-content {
  position: absolute;
  display: flex;
  left: 50%;
  top: 40%;
  width: 82%;
  transform: translate(-50%, -50%);
  text-align: left;
  justify-content: left;
  color: #ffffff;
  /* Adjust the text color as needed */
}

.right_content_button {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 7rem;
   font-family: 'Poppins', sans-serif;
  font-size: 35px;
  font-weight: 350;
  line-height: 50px;
  letter-spacing: 0.03em;
  text-align: left;
}

.welcome-content p {
  text-align: start;
}

.welcome-brand-name {
   font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 48px;
  /* or 137% */
  letter-spacing: 0.05em;
}

.mobile_menu {
  display: none;
}

.welcome-brand-identity {
  margin-bottom: 2rem;
}

.welcome-brand-identity {
   font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 350;
  font-size: 2rem;
  line-height: 2.8rem;
  /* or 150% */
  letter-spacing: 0.03em;
}

.headerCss {
  display: flex;
  margin: 0rem 10rem;
  width: 100%;
  min-height: 3rem;
  align-items: center;

}

.mobile-hide {
  display: block;
}

.home_div {
  user-select: none;
  width: 100%;
}

.mobile-show {
  display: none;
}

.header {
  user-select: none;
  top: 0;
  width: 100%;
  position: sticky;
  top: 0;
  background: white;
  /* z-index: 999; Higher than other sticky elements */
  width: 100%;
  z-index: 100;
  background-color: white;
  padding: 1.2rem 0px;
  /* margin-bottom: 1rem; */
  align-items: center;
  /* min-height: 10rem; */
  box-shadow: 0px 0px 13.6px 0px #66666638;
  display: flex;
  justify-items: center;
  justify-content: space-between;
}

.booking_header {
  /* position: sticky; */
  user-select: none;
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: #faf9f6;
  padding: 25px 0px;
  /* margin-bottom: 1rem; */
  align-items: center;
  justify-content: space-between;
}

.main_news {
  width: 70%;
}

.main_news_container {
  padding: 2rem 4rem 5rem;
  text-align: left;
}

.logo-text:hover {
  cursor: pointer;
}

.logo {
  flex-grow: 1;
  text-align: start;
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap:0.5rem;
  align-items: center;
}

.logo:hover {
  cursor: pointer;
}

.logo-text {
  color: black;
  font-size: 24px;
  margin: 0;
}

.navigation {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 2rem;
}

.menu-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 35px;
  height: 25px;
  cursor: pointer;
}

.menu-icon:hover,
#desktop_menu:hover {
  cursor: pointer;
}

.bar {
  display: flex;
  background-color: black;
  width: 100%;
  /* justify-content: space-between; */
}

.bar-colored {
  height: 3px;
  background-color: #faf9f6;
  transition: transform 0.3s ease-in-out;
  width: 100%;
}

.spacer-mid {
  width: 35%;
}

.spacer-bottom {
  width: 70%;
}

.bar.open:nth-child(2) {
  opacity: 0;
}

.segment-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.segment-link {
  color: #fff;
  font-size: 15px;
  margin: 0 10px;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  text-transform: uppercase;
}

.segment-link-booking {
  color: #000103;
  font-size: 15px;
  margin: 0 10px;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  text-transform: uppercase;
}

.segment-link.active,
.segment-link-booking.active {
  font-weight: bold;
  /* background-color: #FFF; */
  border: 8px;
  color: #00b0b2;
  /* color: #FFF; */
}

.segment-link:hover {
  color: #00b0b2;
}

.mobile-list {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
}

.mobile-link {
  border-top: 1px solid #fff;
  padding: 1rem;
  list-style-type: none;
  color: #000;
  /* font-size: 15px; */
  /* margin: 0 10px; */
  /* width: 100% !important; */
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  text-transform: uppercase;
}

.mobile-link.active {
  font-weight: bold;
  /* background-color: #FFF; */
  border: 8px;
  color: #00b0b2;
  /* color: #FFF; */
}

.mobile-link:hover {
  color: #00b0b2;
}

.scroll-top {
  position: "fixed";
  bottom: "20px";
  right: "20px";
  z-index: "9999";
}

.about-prod-header {
   font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  text-align: start;
  font-size: 33px;
  line-height: 36px;
  letter-spacing: 0.03em;
  color: #000103;
}

.about-prod-header-book {
   font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  font-size: 33px;
  line-height: 36px;
  letter-spacing: 0.03em;
  color: #000103;
}

.about-prod-info {
  text-align: start;
   font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 350;
  font-size: x-large;
  line-height: 30px;
  letter-spacing: 0.03em;
  color: #000103;
}

.about-prod-info-book {
  text-align: center;
   font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 350;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: #000103;
}

.presentation_container {
  display: flex;
}

.segment {
  /* background-color: #A27B5C; */
  color: #fff;
  /* margin-bottom: 20px; */
  width: 100%;
}

.segment.h-100 {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-container {
  display: flex;
  padding: 1rem;
  /* max-width: 100%; */
}

.presentation_calendar {
  width: 40%;
}

.presentation_separator {
  display: block;
  width: 10%;
}

.presentation_contact {
  width: 50%;
}

.image-container {
  display: flex;
  position: relative;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.service_button {
  position: "absolute";
  bottom: "0px";
  left: "0px";
  /* width: "auto"; */
  max-width: 400px !important;
  overflow-wrap: "break-word";
  /* background: /; */
  /* height: "3.5rem"; */
  /* // fontSize: "1rem"; */
  color: "white";
  border: "0";
  font-weight: 700;
  box-shadow: 0px 5px 10px rgba(147, 47, 109, 0.4);
}

.button_container:hover {
  cursor: pointer;
  background-color: black !important;
}

.shadow {
  /* box-shadow: 0px 5px 10px rgba(147, 47, 109, 0.4); */
}

.services_button :hover {
  /* borderColor: 'transparent' , color: "black", background: colors[index], */
}

.mb5 {
  margin-bottom: 5rem;
}

.margin-top {
  margin-top: 2rem;
}

.current_news_img {
  width: 100%;
  border-radius: 10px 10px 0px 0px;
}

.current_news {
  text-align: start;
  margin: 0rem 1rem;
  max-height: 60vh;
  /* padding: 2rem; */
  /* background-color: rgb(223, 223, 223); */
  box-shadow: 0px 5px 10px rgba(147, 47, 109, 0.4);
  border-radius: 10px;
}

.current_news:hover {
  cursor: pointer;
}

.current_news img {
  max-height: 50% !important;
  min-height: 50% !important;
  object-fit: cover;
}

.current_news_container {
  min-height: 40%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.cnc_category {
   font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 750;
  font-size: 10px;
  line-height: 24px;
  /* identical to box height, or 240% */
  letter-spacing: 0.03em;
  color: #00b0b2;
}

.cnc_description {
  margin-top: 0px;
   font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 350;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  letter-spacing: 0.03em;
  color: #000103;
}
.about_us_content {
  margin-left: 2rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 65%;
}
.cnc_title {
   font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 36px;
  /* identical to box height, or 200% */
  letter-spacing: 0.03em;
  color: #000103;
}

.custom-button {
  padding: 10px 20px;
  color: black;
  border-color: white;
  border-width: 2px;
}

.button-container_edit {
  position: absolute;
  bottom: 0px;
  left: 30px;
  width: auto;
  max-width: 450px !important;
  overflow-wrap: break-word;
  align-items: center;
  background: #00b0b2;
  display: flex;
  /* justify-content: center; */
  /* height: 3.5rem; */
  /* font-size: 1rem; */
  color: black;
  border: 0;
  padding: 20px;
  /* max-height: 2vh; */
  font-weight: 700;
}

.image {
  object-fit: cover;
  flex: 0 0 auto;
  width: 80%;
  max-width: 80%;
  height: auto;
}

.dropdown-menu {
  display: none;
  /* other styling for your dropdown */
  opacity: 0;
  animation-name: slideTopToBottom;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
  /* transition: opacity 0.5s ease; */
}

.hover {
  cursor: pointer;
}

.hover:hover + .colored-selector {
  /* display: block !important; */
  /* or 'flex', 'inline', 'inline-block', etc., depending on your layout */
}

.selector {
  color: #013a4e;
  padding: 0.5rem 2rem;
}

.selector:first-child {
  margin-top: 1rem;
}

.selector:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.selector:hover {
  background-color: #013a4e;
  color: white !important;
}

.dropdown-header {
  display: flex;
  justify-content: center;
  align-items: center;
}


.dropdown-menu.open {
  display: grid;
  opacity: 1;
  position: absolute;
  background: white;
  /* padding: 1rem 2rem 0.5rem 2rem; */
}


/* Hover effect */
/* 
#BE:hover,
#MH:hover,
#NG:hover,
#SK:hover,
#HU:hover,
#PL:hover,
#BA:hover,
#AE:hover,
#VE:hover,
#IN:hover,
#IL:hover,
#SY:hover,
#PS:hover,
#QA:hover,
#PK:hover,
#KG:hover,
#BG:hover,
#UA:hover,
#NL:hover,
#SE:hover,
#HR:hover,
#LU:hover,
#BE:hover,
#RS:hover,
#MK:hover,
#ME:hover,
#MH:hover,
#DE:hover,
#CZ:hover,
#SI:hover,
#RO:hover,
#AT:hover,
#CH:hover,
#PT:hover,
#BA:hover,
#PA:hover,
#ES:hover,
#GL:hover,
#QA:hover,
.United_States:hover,
.United_Kingdom:hover,
.New_Zealand:hover,
.France:hover,
.Australia:hover,
.Canada:hover,
.Italy:hover,
.Angola:hover,
.Turkey:hover,
.Greece:hover,
.Denmark:hover,
.Azerbaijan:hover,
.Cyprus:hover,
.Philippines,
.Indonesia:hover {
  fill: lightgray;
  cursor: pointer;
} */

.world-map-image {
  background-color: #f0f0f0;
  /* background color */
  /* transition: transform 0.3s ease; */
  /* smooth transition for hover effect */
}

.rotating_dots {
  display: flex;
  padding-bottom: 10vh;
}

.world-map-image:hover {
  /* transform: scale(1.05); */
  /* example hover effect */
  cursor: pointer;
}

.menu_big {
  /* position: absolute; */
  z-index: 999;
  height: 100vh;
  width: 100%;
  /* Set initial width */
  display: flex;
  background: #f6f4f2;
  overflow: hidden;
  /* Optional, to hide content when the width is 0 */
  animation-name: slideRightToLeft;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
}

.colored-selector {
  border-left: 2px solid #013A4E;
  /* height: 2px;
  background-color: #013a4e;
  transition: transform 0.3s ease-in-out;
  width: 100%;
  margin-right: 7rem; */
}

@keyframes slideRightToLeft {
  from {
    transform: translateX(100%);
    border-top: 0px solid #013a4e;
  }
  to {
    transform: translateX(0);
    border-top: 2px solid #013a4e;
  }
}

@keyframes slideTopToBottom {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.menu_big_gove {
  display: none;
}

.my_button {
  /* Main BTN */

/* Auto layout */
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 20px 30px;
gap: 10px;
color: white;
width: 329px;
font-weight: 500;
height: 49px;

background: #013A4E;
box-shadow: 0px 4px 15.3px rgba(0, 0, 0, 0.25);
border-radius: 100px;

/* Inside auto layout */
flex: none;
order: 1;
flex-grow: 0;

  
}

.my_button_contact_menu {
  /* Main BTN */

/* Auto layout */
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 20px 30px;
gap: 10px;

width: 329px;
height: 49px;
color: #013A4E;
font-size: 18px;
background: #FFFFFF;
box-shadow: 0px 4px 15.3px rgba(0, 0, 0, 0.25);
border-radius: 100px;

/* Inside auto layout */
flex: none;
order: 1;
flex-grow: 0;

}

.my_button_reverse {
  user-select: none;
  padding: 0.7rem 1.2rem;
  background: #013a4e;
  border-radius: 30px;
  color: white;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  box-shadow: 0px 5px 13px 0px gray;
  border-color: white;
  border-width: 2px;
}

.my_button_reverse:hover {
  border-color: "white";
  background-color: white;
  color: #013a4e;
  cursor: pointer;
}

.my_button:hover {
  border-color: "white";
  background-color: #013a4e;
  color: white;
  cursor: pointer;
}

.image:first-child {
  width: 35%;
  max-width: 35%;
}

.image:last-child {
  width: 60%;
  max-width: 60%;
}

#news {
  margin-bottom: 5rem;
  display: "grid";
  grid-template-columns: "repeat(auto-fit, minmax(400px, 1fr))";
  gap: 20px;
}

.second_desc {
  padding: 20px;
}

#news_content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 20px;
  margin-bottom: 5rem;
}

.news_content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 20px;
  margin-bottom: 5rem;
}

.admin_news_content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 20px;
  margin-bottom: 5rem;
}

.mouse_hover:hover {
  cursor: pointer;
}

#all_services {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 20px;
  margin-bottom: 5rem;
}

#member_data {
  display: none;
}

.nkit {
  display: flex;
  flex-direction: row;
  padding: 0rem 10rem 1rem 10rem;
  justify-content: flex-end;
  
}

footer {
  position: fixed;
  background: white;
  /* min-height: 13vh; */
  padding-top: 1rem;
  box-shadow: 0px 0px 13.6px 0px #66666638;

  /* box-shadow: 0px 0px 13.6px 0px #66666638; */
  min-width: 100%;
  bottom: 0;
}

#footer_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  margin: 0rem 10rem;
  padding: 0rem 0rem 1rem 0rem;
  color: #013a4e;
}

.brand_copyright {
  font-size: 16px;
}

#footer_info {
  display: block;
  width: 30%;
  /* padding-top: 2rem; */
}

.footer_map {
  width: 100%;
  display: flex;
  /* min-height: 40vh; */
  justify-content: end;
  align-items: center;
}

.brand_name_short {
   font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 16px;
}

.brand_name_long {
   font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 350;
  font-size: medium;
  line-height: 24px;
  /* or 150% */
  letter-spacing: 0.03em;
  color: #013a4e;
}

#brand_f {
  display: flex;
  justify-content: center;
}

#last_book {
  background-color: #da327d;
  /* text-align: start; */
  padding-bottom: 3rem;
}

.brand_phone,
.brand_address {
   font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 32px;
  color: white;
}

.text-color-white {
  color: white;
}

.third-row {
  object-fit: cover;
  flex: 0 0 auto;
  width: 80%;
  max-width: 80%;
  height: auto;
}

.third-row:first-child {
  width: 60%;
  max-width: 60%;
}

.third-row:last-child {
  width: 35%;
  max-width: 35%;
}

.segment.h-100-top {
  min-height: 100vh;
  display: flex;
  justify-content: center;
}

.segment.h-100 h2 {
  font-size: 48px;
}

.segment h2 {
  font-size: 24px;
  margin-top: 0;
}

.hide {
  display: none !important;
}
.contact_content {
  height: 75vh;
  display: flex;
}
.cancel_menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cancel_menu:hover {
  cursor: pointer;
}

.language-dropdown {
  margin-left: 1rem;
}

.padding20 {
  padding: 20px 0px;
}
/* styles.css */
.our_team-container {
  padding: 0rem 10rem;
  background: white;
  /* margin-bottom: 1rem; */
  overflow: none;
}

.our_team-header {
  font-size: 4.5rem;
  padding-bottom: 2rem;
}

.our_team-sub-header {
  font-size: 1.5rem;
  line-height: 3.5rem;
}

.our_team-hr {
  background: white;
  height: 1.5px;
  margin: 0.3rem 0rem;
  width: 5.5rem;
  border-radius: 2px;
}

.our_team-image {
  width: auto;
  height: 450px;
}

.our_team-section-title {
  font-size: 24px;
  line-height: 27px;
  font-weight: 400;
  color: #013A4E;
}

.our_team-section-content {
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  color: #013A4E;
}

.our_team-flex-column {
  display: flex;
  flex-direction: column;
}

.our_team-flex-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.our_team-employee-img {
  display: flex;
  align-items: flex-end;
  min-height: 35vh;
}

.our_team-contact-card {
  border-radius: 30px;
  background: #F6F4F2;
  padding: 2rem;
  box-shadow: 0px 15px 20px 0px #1E1E1E1A;
}


.current_package {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 20px;
  margin-bottom: 5rem;
}

.button_container {
  left: 30px;
}

.main_container {
  min-height: 100vh;
  display: flex;
  overflow-wrap: break-word;
}

.other_news {
  width: 30%;
  margin-top: 5rem;
}

.svg_map {
  width: 100%;
  margin-left: -8rem;
}

.other_news_header {
  display: none;
}

.news_title {
  font-size: large;
  font-weight: bold;
}

.mobile_show {
  display: none !important;
}

.contact_info {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  /* This is the default value and can be omitted */
  padding: 1rem 0rem;
  /* font-size: x-large; */
}

.menu_content_left, .menu_content_right {
  width: 50%;
  flex: 0 0 50%; /* This is important - it prevents flex items from growing or shrinking */
  box-sizing: border-box; /* Makes sure padding is included in width calculation */
  overflow-y: auto; /* Allow scrolling if content is too tall */
}


.menu_content_left {
  background: white;
  border-right: 2px solid #013a4e;
  /* width: 35%; */
  display: flex;
  text-align: left;
  /* padding-top: 6rem; */
  /* padding-bottom: 10rem; */
  padding: 5rem 0rem 0rem 10rem;
  /* justify-content: space-between; */
  flex-direction: column;
  gap: 0.5rem;
  /* min-height: 65vh; */
  /* max-height: 65vh; */
}

.menu_content_contact_left {
  background: white;
  border-right: 2px solid #013a4e;
  /* width: 35%; */
  display: flex;
  /* padding-top: 1; */
  justify-content: space-between;
  text-align: left;
  /* padding-top: 6rem; */
  /* padding-bottom: 10rem; */
  /* padding: 5rem 0rem 0rem 10rem; */
  /* justify-content: space-between; */
  flex-direction: column;
  gap: 0.5rem;
  /* min-height: 65vh; */
  /* max-height: 65vh; */
}

.menu_content_right  {
  /* width: 50%; */
  /* max-width: 50%; */
  color: white;
  font-family: Poppins;
font-weight: 500;
/* font-size: 1.5rem; */
line-height: 200%;
letter-spacing: 0%;

background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)), url('./img/contact-us.jpeg');
background-size: cover;
background-position: center;

  display: flex;
  text-align: left;
  /* margin-right: 19rem; */
  padding:5rem 12rem 10rem 7rem;
  /* min-width: 500px !important; */
  /* padding-bottom: 10rem; */
  justify-content: space-between;
  flex-direction: column;
  gap:2rem;
  /* min-height: 41vh; */
  /* max-height: 38vh; */
  background-size: cover;
  background-repeat: no-repeat; /* Prevents the image from repeating */
  background-position: center; /* Centers the image */

}
.menu_content_contact  {
  /* width: 50%; */
  /* max-width: 50%; */
  color: #013a4e;
  font-family: Poppins;
font-weight: 500;
font-size: 1.5rem;
line-height: 100%;
letter-spacing: 0%;

/* background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)), url('./img/contact-us.jpeg'); */
background-size: cover;
background-position: center;

  display: flex;
  text-align: left;
  /* margin-right: 19rem; */
  padding:5rem 12rem 10rem 7rem;
  /* min-width: 500px !important; */
  /* padding-bottom: 10rem; */
  justify-content: space-between;
  flex-direction: column;
  gap:2rem;
  /* min-height: 41vh; */
  /* max-height: 38vh; */
  background-size: cover;
  background-repeat: no-repeat; /* Prevents the image from repeating */
  background-position: center; /* Centers the image */

}

.menu_item {
  width: 100%;
  font-size: 45px;
  padding: 1rem 0rem;
  color: #013A4E;
}

.menu_item:hover {
  font-weight: bold !important;
  /* text-decoration: dotted !important; */
  /* background-color: red !important; */
}

.menu_icon {
  background: #013a4e;
  color: white;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.2rem;
  border-radius: 20px;
  margin: 0.3rem;
}

.home_menu_icon {
  background: #013a4e;
  color: white;
  width: 1.2rem !important;
  height: 1.2rem !important;
  padding: 0.2rem;
  border-radius: 20px;
  margin: 0.3rem;
}
.home_readmore {
  min-height: 35vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.right_container {
  display: block;
  width: 25%;
}

.contact_info_icons {
  display: flex;
  justify-content: left;
  
}

.contact_info_icons:hover {
  cursor: pointer;
}

.brand_logo_img {
  width: 2.5vw;
  padding-right: 0.5rem;
  border-right: 2px solid #006081;
}
.brand_name_container {
  color: #013A4E;
}
.footer_logo {
  width: 1.7vw;
}

.lang_menu_item {
  padding: 0.5rem 2rem !important;
}

.dropdown-menu.open {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.container_one {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.container_two {
  min-height: 45vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
/* Responsive Styles */
.home_readmore_tablet {
  display: none;
}
@media only screen and (max-width: 1350px) and (min-width: 1000px) {
  .mobile_screen_show{
    display: block !important;
  }
    .brand_name_subtitle {
        font-size: 16px;
      }
  .home_readmore_tablet {
    display: none;
  }
  .right_container {
    display: none;
  }

  .brand_name_contact {
    padding-top: 2vh;
  }

  .home_readmore {
    min-height: 29vh;
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: flex-end; */
  }
  .homeb {
    margin-bottom: 2rem;
  }

  .brand_name {
    padding-top: 10vh;
  }

  .tablet_mobile {
    color: #013a4e;
    font-size: 1rem;
    font-weight: 600;
    user-select: none;
  }
  .dropdown-menu.open {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .contact_info {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    /* This is the default value and can be omitted */
    padding: 1rem 0rem;
    align-items: start;
    font-weight: 800;
  }
  .contact_info_icons {
    display: flex;
    justify-content: left !important;
  }
  .menu_content_left {
    padding-bottom: 14rem;
  }
  .menu_content_left,
  .menu_content_right {
    padding: 10rem 2rem 0rem 2rem;
  }
  .menu_item {
    width: 100%;
    font-size: 35px;
    padding: 1rem 0rem;
  }
  .menu_icon {
    background: #013a4e;
    color: white;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.2rem;
    border-radius: 20px;
    margin: 0.3rem;
  }
  .brand_copyright {
    font-size: 11px;
  }
  .header {
    min-height: 3rem;
  }
  .brand_logo_img {
    width: 4vw;
  }
}

@media (max-width: 1440px) {
  .small_screen_hide {
    display: none !important; /* Ensure it overrides other styles */
  }
  .mobile_screen_show {
    display: block !important;
  }
}

.mobile_screen_show_smallest {
  display: none !important;
}


@media only screen and (max-width: 1000px) and (min-width: 768px) {

  .right_container {
    display: none;
  }
  .container_legal {
    grid-template-columns:repeat(auto-fill, minmax(calc(50%-20px), 1fr))
  }
  #about_us {
    padding: 2rem 4rem;
    overflow: scroll;
  }
  .about_right_container {
    min-height: 45vh;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    gap: 20px;
    overflow: scroll;
  }
  .brand_name_contact {
    padding-top: 2vh !important;
  }
  .button_container {
    left: 5px;
  }
  .small_screen_hide {
    display: none !important;
  }

  .home_readmore {
    min-height: 29vh;
    display: flex;
    flex-direction: column;
    /* justify-content: flex-end; */
  }
  .container_one {
    width: 100%;
    flex-grow: 0;
  }
  .container_two {
    min-height: auto;
  }
  .home_container {
    display: flex;
    flex-direction: column;
    padding: 0rem 4rem;
    max-height: 60vh;
  }
  .home_readmore_tablet {
    min-height: 20vh;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .carousel-container {
    padding-bottom: 4rem;
  }
  .homeb {
    margin-bottom: 2rem;
  }

  .brand_name {
    padding-top: 5vh;
  }

  .dropdown-menu.open {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .menu_content_left {
    margin: 0rem 3rem 0rem 4rem;
  }
  .menu_content_right {
    margin-right: 4rem;
  }
  .contact_info {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    /* This is the default value and can be omitted */
    padding: 1rem 0rem;
    align-items: start;
    font-weight: 800;
  }
  .our_team-container {
    padding: 1rem 4rem;
    display: flex;
    flex-direction: column;
  }
  .news_title {
    font-size: auto;
    font-weight: bold;
  }
  .menu_item {
    width: 100%;
    font-size: 35px;
    padding: 1rem 0rem;
  }
  .contact_info_icons {
    display: flex;
    justify-content: left;
  }
  .brand_name {
    padding-right: 2rem;
    font-size: 80px;
  }
  .headerCss {
    margin: 0rem 4rem;
  }

  #footer_container {
    margin: 0rem 4rem;
  }
  .main_news_container {
    padding: 2rem 1rem 5rem;
    text-align: left;
    overflow-wrap: break-word;
  }
  .news_add_container {
    font-size: 15px;
  }
  .brand_copyright {
    font-size: 8px;
  }
  .header {
    min-height: 3rem;
  }
  .brand_logo_img {
    width: 6vw;
  }
}
.map_contant {
  width: 100%;
  margin-top:-15rem;
}

/* .container_legal {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 50px;
  justify-content: center;
  align-items: center;
  padding-bottom: 10rem;
} */

#container_legal {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 10rem;
  
}
.legal_item_icon {
  /* font-size: 3rem; */
}
.container_legal_item {
  min-height: 14rem;
  flex: 0 0 calc(25% - 20px);
  background: #013A4E;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: small;
  gap: 1rem;
  border-radius: 15px;
  color: white;
  padding: 20px;
  margin: 10px;
}

* {
  box-sizing: border-box;
}


.column_legal {
  box-sizing: border-box;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background: #f9f9f9;
  min-height: 250px; /* Fixed height */
  max-height: 500px;
  position: relative;
  overflow: hidden;
  text-align: center;
}

.column_legal_inner {
  box-sizing: border-box;
  /* padding: 15px; */
  /* border: 1px solid #ccc; */
  border-radius: 8px;
  /* background: #f9f9f9; */
  min-height: 250px; /* Fixed height */
  /* max-height: 500px; */
  position: relative;
  overflow: hidden;
  text-align: center;
}

.column_legal img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease-in-out;
}

.column_legal .content {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Ensures text starts from the top */
  align-items: flex-start; /* Aligns text to the left */
  background: rgba(255, 255, 255, 0.9);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  box-sizing: border-box; /* Includes padding in width and height calculations */
  overflow: hidden; /* Prevents text from overflowing */
}

.column_legal:hover img {
  opacity: 0;
}

.column_legal:hover .content {
  opacity: 1;
}

.column_legal h2 {
  font-size: 18px;
  text-align: left;
  font-weight: bold;
  color: #333;
}

.list {
  margin: 0;
  text-align: left;
}

.listItem {
  margin-bottom: 8px;
  font-size: 14px;
  color: #555;
}


@media screen and (max-width: 768px) {
  .our_team {
    padding: 0rem 0rem;
  }

  .my_button {
    width: 250px;
  }
  
  .dropdown-header {
    font-size: 1rem;
  }

  .menu_item {
    font-size: 2rem;
    padding: 0rem 1rem;
    text-align: start;
  }
  .menu_item:last-child{
    margin-bottom: 1rem;
  }
  
  .our_team-flex-row {
    /* flex-direction: row; */
    /* display: block; */
  }

  .container_legal_item {
    min-height: 14rem;
    flex: 0 0 calc(100% - 20px);
    background: #013A4E;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: small;
    gap: 1rem;
    border-radius: 15px;
    color: white;
    padding: 20px;
    margin: 10px;
  }
  .our_team-header {
    font-size: 1.5rem;
  }
  .our_team-sub-header {
    font-size: 1rem;
  }

  .our_team-image {
    height: 300px;
  }

  .our_team-container {
    padding: 1rem;
    /* display: block; */
  }
  .our_team-section-content {
 
    word-break: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    word-break: break-word;}
  .data-employee {
    display: block  !important;
  }
.container_legal {
  grid-template-columns:repeat(auto-fill, minmax(calc(100%), 1fr))
}
 
  .employee_contact {
    border: none;
    box-shadow: none !important;
    padding: 0px !important;
  }
  .carousel-container {
    /* padding-bottom: 4rem; */
  }
  #our_team {
    padding: 1rem 1rem 0rem 1rem;
  }
  #contant_form {
    padding: 1rem 1.5rem 1rem 1.5rem;
  }
  .map_contant {
    margin-top: 0rem;
  }
  .mobile_screen_show_smallest {
    display: block !important;
  }
  .flexDa {
    flex-direction: row !important;
  }
  .mobile_screen_show {
    display: none !important;
  }
  .contact_content {
    height: auto;
    flex-direction: column;
    display: flex;
  }
  .brand_name_subtitle {
    font-size: 14px;
    margin-bottom: 10rem;
  }
  .contact_info {
    margin-top: 3rem;
    padding: 0rem !important;
  }
  .svg_map {
    margin-top: 4rem;
    width: 100%;
    margin-left: 0rem;
    margin-bottom: 2rem;
  }
  .about_us_content {
    margin-left: 0rem;

    width: 100%;
  }

  .brand_name_contact {
    padding-top: 2vh !important;
  }
  .about_us_container {
    height: auto;
    display: flex;
    flex-direction: column;
  }
  #about_us {
    padding: 1rem 1.5rem 1rem 1.5rem;
    overflow: scroll;
  }
  .right_container {
    display: none;
  }
  .button_container {
    left: 5px;
  }
  .small_screen_hide {
    display: none !important;
  }

  .home_readmore {
    min-height: 29vh;
    display: flex;
    flex-direction: column;
    /* justify-content: flex-end; */
  }
  .container_one {
    width: 100%;
    flex-grow: 0;
  }
  .container_two {
    min-height: auto;
  }
  .home_container {
    display: flex;
    flex-direction: column;
    padding: 0rem 4rem;
    justify-content: space-around;
    max-height: 60vh;
  }
  .home_readmore_tablet {
    min-height: 20vh;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .brand_name_description:last-child {
    display: none;
  }
  .homeb {
    margin-bottom: 2rem;
  }
  .rotating_dots {
    display: flex;
    padding-bottom: 3vh;
  }

  .brand_name {
    padding-top: 5vh;
    font-size: 2.5rem;
    padding-bottom: 1rem;
    font-weight: 600;
  }

  .menu_big {
    /* margin-top: 5rem; */
    height: 100%;
    width: 100%;
    /* Set initial width */
    display: flex;
    flex-direction: column;
    background: white;
    overflow: hidden;
    /* Optional, to hide content when the width is 0 */
    animation-name: slideRightToLeft;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
  }
  .contact_info_icons {
    justify-content: start;
    padding:2rem 1rem;
  }
  

  .homepage_map {
    width: 90%;
  }
  .home_subheader {
    font-size: 16px;
    margin-bottom: 1rem;
  }
  .contact_info {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    /* This is the default value and can be omitted */
    padding: 3rem 0rem;
    align-items: start;
    font-weight: 800;
  }
  .menu_content_right {
    display: none;
    padding: 1rem;
    /* Reset padding */
    width: auto;
    /* Reset width */
    /* display: block; */
    /* Reset display */
    text-align: center;
    /* Reset text-align */
    margin-right: 0;
    /* Reset margin-right */
    justify-content: flex-start;
    /* Reset justify-content */
    flex-direction: row;
    /* Reset flex-direction */
    min-height: auto;
    /* Reset min-height */
    max-height: none;
    /* Reset max-height */
  }
  .menu_content_right h1 {
    white-space: normal; /* Allows line breaks */
    overflow-wrap: break-word; /* Break long words if needed */
  }
  
  .menu_content_left {
    padding: 2rem 0rem 0rem 0rem!important;
    border-right: 2px solid #013a4e;
    width: 100%;
    display: flex;
    margin: 0rem 0rem 0rem 0rem;
    flex-direction: column;
    text-align: center;
    justify-content: start;
    overflow-y: scroll !important;
    min-height: 100vh;
    -webkit-overflow-scrolling: touch !important; /* Smooth scrolling for iOS */
    position: relative; /* Ensure proper stacking context */
    
}
  .other_news_header {
    display: block;
  }
  .other_news {
    width: 100%;
    margin-top: 0rem;
  }
  .welcome-content {
    top: 60%;
    width: 80%;
  }
  .main_container {
    display: block;
    overflow-wrap: break-word;
  }
  .main_news {
    width: 100%;
  }
  .main_news_container {
    padding: 0rem 0rem 3rem;
  }
  .button_container {
    left: 5px;
  }
  .welcome-brand-name {
    margin-bottom: 1rem;
     font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 3rem;
    line-height: 4rem;
    letter-spacing: 0.05em;
  }
  .about_us_top {
    display: block;
    width: 100%;
  }
  .about_us_top_left {
    /* Remove the properties for the responsive style */
    width: initial;
    display: initial;
    margin-top: initial;
    padding: initial;
  }
  .flex-container {
    padding: 0rem;
  }
  .mobile_hide {
    display: block;
  }
  .selector {
    color: #013a4e;
    padding: 0.5rem 1rem;
  }
  .dropdown-menu.open {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  .selector:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .current_news {
    text-align: start;
    margin: 0rem 0rem;
    max-height: 100%;
    /* padding: 2rem; */
    /* background-color: rgb(223, 223, 223); */
    box-shadow: 0px 5px 10px rgba(147, 47, 109, 0.4);
    border-radius: 10px;
    margin-bottom: 3rem;
  }
  .opened {
    max-width: 60% !important;
  }
  .about_us_header {
    width: auto;
  }
  .booking_header {
    background-color: #000103;
  }
  #equipment {
    max-width: 100%;
    /* margin: auto; */
  }
  #about_us_content,
  #prices_container,
  #services,
  #news_container {
    padding-top: 5rem;
    
    /* max-width: 100%; */
  }
  #about_us_content {
  }
  #equipment img {
    max-height: 100%;
  }
  .padding20 {
    padding: 20px;
  }
  .welcome-brand-identity {
    margin-top: 2rem;
    font-family: "Avenir LT Std";
    font-style: normal;
    font-weight: 350;
    font-size: 1.1rem;
    line-height: 1.5rem;
    letter-spacing: 0.03em;
  }
  .segment.h-100 h2 {
    font-size: 32px;
  }
  .mobile-hide {
    display: none;
  }
  .mobile-show {
    display: block;
  }
  .second_desc {
    padding: 40px 0px;
  }
  .footer_map {
    min-height: auto;
  }
  .about-us-prod-info {
    padding: 0px 0px 40px 0px;
  }
  .segment-list {
    display: none;
  }
  .mobile_menu {
    /* display: flex; */
    /* background-color: red; */
  }
  .mobile_menu_show {
    display: block;
  }
  .mobile_menu_hide {
    display: none;
  }
  .about-prod-header-book {
    text-align: start;
  }
  .about-prod-info-book {
    text-align: start;
  }
  .language-dropdown {
    display: none;
  }
  .navigation.open ul.segment-list {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #3f4e4f;
    padding: 10px 0;
    z-index: 1;
    margin-top: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out;
  }
  #last_book {
    text-align: start;
  }
  .navigation.open ul.segment-list li {
    margin: 10px 0;
    display: flex;
    justify-content: center;
  }
  .navigation.open ul.segment-list li.segment-link {
    color: #fff;
  }
  .navigation.open ul.segment-list li.segment-link.active {
    color: #a27b5c;
  }
  .navigation.open ul.segment-list li.segment-link:hover {
    color: #a27b5c;
  }
  .navigation.open {
    position: relative;
  }
  .navigation.open .bar:nth-child(1) {
    transform: translateY(8px) rotate(-45deg);
  }
  .navigation.open .bar:nth-child(2) {
    transform: scaleX(0);
  }
  .navigation.open .bar:nth-child(3) {
    transform: translateY(-8px) rotate(45deg);
  }
  .navigation.open ul.segment-list {
    opacity: 1;
    visibility: visible;
  }
  #news {
    margin-bottom: 5rem;
    display: "grid";
    gap: 20px;
  }
  #all_services {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
    gap: 20px;
    margin-bottom: 5rem;
  }
  .presentation_container {
    display: block;
  }
  .presentation_calendar {
    width: 100%;
  }
  .presentation_separator {
    display: none;
  }
  .presentation_contact {
    width: 100%;
    margin-top: 1rem;
  }
  #news_content {
    display: block;
    /* grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); */
    gap: 0px;
    margin-bottom: 5rem;
  }
  .segment-list.hide {
    display: none;
  }
  .about_us_intro {
    display: block;
  }
  .about_us_bottom {
    display: none;
  }
  .current_package {
    display: grid;
    grid-template-columns: auto;
    gap: 20px;
    margin-bottom: 5rem;
  }
  .nkit {
    padding: 0rem 1rem 1rem 1rem;
    font-size: 0.8rem !important;
  }
  .mobile_show {
    display: block !important;
  }
  .mobile_hide {
    display: none;
  }
  .selector {
    color: #013a4e;
    padding: 0.5rem 1rem;
  }
  .dropdown-menu.open {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .selector:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  /* .brand_name {
        padding-right: 2rem;
        font-size: 80px;
    } */
  .headerCss {
    margin: 0rem 1.5rem;
  }
  .home_container {
    padding: 0rem 1.5rem;    
    max-height: 60vh;

    justify-content: space-between;
  }
  #footer_container {
    margin: 0rem 1rem;
  }
  .main_news_container {
    padding: 2rem 1rem 5rem;
    text-align: left;
    overflow-wrap: break-word;
  }
  .news_add_container {
    font-size: 15px;
  }
  .brand_copyright {
    font-size: 7px;
  }
  .header {
    min-height: 3rem;
  }
  .brand_logo_img {
    width: 12vw;
  }
  #footer_info {
    width: 65%;
  }
  .footer_logo {
    width: 7vw;
  }
  .right_container {
    display: none;
  }
}

/* AboutUs.css */
.App.sticky-header-header {
  /* position: -webkit-sticky; */

  z-index: 999;
  background-color: white;
  max-height: 5rem;
  min-height: 5rem;
  min-width: 100%;
  top: 0;
  box-shadow: 0px 0px 13.6px 0px #66666638;

}
 

/* Add these new styles for sticky elements */
.sticky-header {
  /* position: sticky; */
  /* top: 0; */
  /* background-color: white; Add background to prevent content showing through */
  /* z-index: 10; Ensure it stays above other content */
  /* padding: 20px 0; */
  width: 100%;
  height: auto;
}

/* Add styles to ensure the body and html are scrollable */
html, body {
  height: 100%;
  overflow-y: auto !important;
}

.about_us_map {
  min-width: 60%;
  margin-left: -5rem;
  /* margin-top: -7rem; */
  /* position: sticky; */
  top: 100px; /* Adjust this value based on your header height */
  align-self: flex-start; /* Important for sticky positioning in a flex container */
}

/* Fix the container to allow for proper scrolling with sticky elements */
.about_us_container {
  display: flex;
  min-height: auto; /* Allow container to expand with content */
  max-height: none;
  overflow: visible;
  height: auto; /* Let the height be determined by content */
}

/* Adjust the content area for better scrolling with sticky map */
.about-us-content {
  padding-right: 5rem;
  line-height: 1.6;
  color: #013A4E;
  width: 40%; /* Set fixed width */
  height: auto; /* Allow content to determine height */
  overflow: visible;
}

/* Add some padding at the bottom for better scrolling experience */
#about_us {
  padding-bottom: 50px;
  height: auto;
  overflow: visible;
  box-shadow: 0px 0px 13.6px 0px #66666638;

}

/* Keep your existing styles below */
.about-us-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: 'Arial', sans-serif;
  color: #333;
}

.about-us-header {
  text-align: center;
  margin-bottom: 50px;
}

.about-us-title {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 15px;
  color: #013A4E;
}

.about-us-divider {
  width: 80px;
  height: 4px;
  background-color: #013a4e; /* Gold color for law firm */
  margin: 0 auto;
}

.about-us-section {
  color: #013A4E;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.about-us-section:last-child {
  border-bottom: none;
}

.about-us-subtitle {
  font-size: 22px;
  font-weight: 600;
  color: #013A4E;
  margin-bottom: 15px;
}

.about-us-paragraph {
  margin-bottom: 15px;
  font-size: 16px;
  text-align: justify;
}

.about-us-list {
  list-style-type: none;
  padding-left: 20px;
  margin-bottom: 20px;
}

.about-us-list-item {
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
}

.about-us-list-item::before {
  content: "•";
  color: #013a4e;
  font-weight: bold;
  position: absolute;
  left: 0;
}

.about-us-languages {
  display: flex;
  flex-wrap: wrap;
}

.about-us-languages .about-us-list-item {
  width: 33%;
  min-width: 150px;
}

.about-us-highlight {
  font-weight: 600;
  font-style: italic;
  color: #2c3e50;
}

.map-sticky {
  /* background-color: red; */
}

.about-us-conclusion {
  background-color: #f5f5f5;
  padding: 20px;
  max-height: 100px;
  border-left: 4px solid #013a4e;
  margin-top: 20px;
}

.about-us-all {
  display: flex;
  flex-direction: row;
  gap: 5rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .about_us_container {
    flex-direction: column;
  }

  .map-sticky {
    padding: 3rem 0rem;
  }
  .about-us-all {
    display: flex;
    flex-direction: column;
    gap: 0rem;
  }
  
  .about-us-content,
  .about_us_map {
    padding-right: 0px;
    padding :2rem 0rem;
    max-width: 100%;
    min-width: 100%;
    position: static;
    margin-left: 0;
  }
  .about-us-conclusion {
    margin-top: 0px;
    max-height: fit-content;
  }

  html, body {
    /* height: auto; */
    list-style-type:none;
  }

  .about-us-list-item::before {
    display: none;
  }
  
  .about-us-title {
    font-size: 28px;
  }
  .about-us-list-item {
    position: static;
  }
  .about-us-list {
    list-style-type: disc;
  }
  
  .about-us-languages .about-us-list-item {
    width: 50%;
  }
}

@media (max-width: 480px) {
  .about-us-languages .about-us-list-item {
    width: 100%;
  }
}