.image-uploader {
    margin-bottom: 1.5rem;
  }
  
  .uploader-label {
    display: block;
    font-weight: 500;
    margin-bottom: 0.5rem;
    color: #4b5563;
  }
  
  .uploader-dropzone {
    border: 2px dashed #d1d5db;
    border-radius: 8px;
    padding: 1.5rem;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s ease;
    background-color: #f9fafb;
    position: relative;
  }
  
  .uploader-dropzone:hover {
    border-color: #9ca3af;
    background-color: #f3f4f6;
  }
  
  .uploader-dropzone.dragging {
    border-color: #4f46e5;
    background-color: rgba(79, 70, 229, 0.05);
  }
  
  .uploader-dropzone.has-file {
    border-style: solid;
    border-color: #e5e7eb;
    padding: 0.75rem;
  }
  
  .upload-placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    color: #6b7280;
  }
  
  .upload-icon {
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e5e7eb;
    border-radius: 50%;
    color: #4b5563;
  }
  
  .upload-text p {
    margin: 0;
  }
  
  .upload-hint {
    font-size: 0.875rem;
    color: #9ca3af;
    margin-top: 0.5rem !important;
  }
  
  .file-input {
    display: none;
  }
  
  .image-preview {
    position: relative;
    width: 100%;
    text-align: center;
  }
  
  .image-preview img {
    max-width: 100%;
    object-fit: contain;
    border-radius: 4px;
  }
  
  .image-info {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    font-size: 0.875rem;
    color: #6b7280;
  }
  
  .image-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
  }
  
  .remove-image {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #ef4444;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    line-height: 1;
    transition: all 0.2s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .remove-image:hover {
    background-color: #dc2626;
    transform: scale(1.1);
  }
  
  @media (max-width: 768px) {
    .uploader-dropzone {
      padding: 1rem;
    }
    
    .upload-icon {
      height: 36px;
      width: 36px;
    }
  }