/* BlogAdmin.css */

.blog-admin-container {
    /* max-width: 1200px; */
    margin: 0 10rem;
    padding: 2rem 0rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  .admin-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    flex-wrap: wrap;
  }
  
  .admin-header h1 {
    color: #013A4E;
    margin: 0;
    font-size: 2.5rem;
  }
  
  .admin-actions {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    flex-wrap: wrap;
  }
  
  .filter-controls {
    display: flex;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #ddd;
  }
  
  .filter-button {
    padding: 0.5rem 1rem;
    background: #f5f5f5;
    border: none;
    cursor: pointer;
    font-size: 0.9rem;
    transition: all 0.2s;
  }
  
  .filter-button:not(:last-child) {
    border-right: 1px solid #ddd;
  }
  
  .filter-button.active {
    background: #013A4E;
    color: white;
  }
  
  .create-post-button {
    padding: 0.6rem 1.2rem;
    background: #013A4E;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    font-weight: 500;
    transition: background 0.2s;
  }
  
  .create-post-button:hover {
    background: #025670;
  }
  
  .success-message {
    background-color: #d4edda;
    color: #155724;
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 1.5rem;
    border: 1px solid #c3e6cb;
  }
  
  .admin-loading {
    display: flex;
    justify-content: center;
    padding: 3rem;
    font-size: 1.2rem;
    color: #666;
  }
  
  .admin-error {
    background-color: #f8d7da;
    color: #721c24;
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 1.5rem;
    border: 1px solid #f5c6cb;
  }
  
  .admin-empty {
    text-align: center;
    padding: 3rem;
    font-size: 1.2rem;
    color: #666;
    background: #f9f9f9;
    border-radius: 4px;
  }
  
  .posts-table-wrapper {
    overflow-x: auto;
    margin-bottom: 2rem;
  }
  
  .posts-table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    background-color: white;
  }
  
  .posts-table th,
  .posts-table td {
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid #eee;
  }
  
  .posts-table th {
    background-color: #f5f5f5;
    font-weight: 600;
    color: #333;
  }
  
  .posts-table tr:hover {
    background-color: #f9f9f9;
  }
  
  .post-title-cell {
    max-width: 300px;
  }
  
  .post-title {
    font-weight: 500;
    display: flex;
    flex-direction: column;
  }
  
  .post-subtitle {
    color: #666;
    font-size: 0.9rem;
    font-weight: normal;
    margin-top: 0.2rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  .status-badge {
    display: inline-block;
    padding: 0.25rem 0.75rem;
    border-radius: 2rem;
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: uppercase;
  }
  
  .status-badge.published {
    background-color: #d1e7dd;
    color: #0a6e31;
  }
  
  .status-badge.draft {
    background-color: #e2e3e5;
    color: #383d41;
  }
  
  .actions-cell {
    white-space: nowrap;
  }
  
  .post-actions {
    display: flex;
    gap: 0.5rem;
  }
  
  .post-actions button,
  .post-actions a {
    padding: 0.4rem 0.75rem;
    border-radius: 4px;
    font-size: 0.85rem;
    cursor: pointer;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    transition: all 0.2s;
    border: none;
  }
  
  .view-button {
    background-color: #e9ecef;
    color: #495057;
  }
  
  .view-button:hover {
    background-color: #dee2e6;
  }
  
  .edit-button {
    background-color: #cff4fc;
    color: #055160;
  }
  
  .edit-button:hover {
    background-color: #b6effb;
  }
  
  .delete-button {
    background-color: #f8d7da;
    color: #721c24;
  }
  
  .delete-button:hover {
    background-color: #f5c2c7;
  }
  
  .admin-pagination {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    gap: 0.3rem;
  }
  
  .pagination-button {
    padding: 0.5rem 0.8rem;
    border: 1px solid #ddd;
    background-color: white;
    cursor: pointer;
    border-radius: 4px;
    transition: all 0.2s;
  }
  
  .pagination-button.active {
    background-color: #013A4E;
    color: white;
    border-color: #013A4E;
  }
  
  .pagination-button:hover:not(:disabled) {
    background-color: #f0f0f0;
  }
  
  .pagination-button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  .delete-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .delete-modal {
    background-color: white;
    border-radius: 8px;
    padding: 2rem;
    width: 100%;
    max-width: 500px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .delete-modal h3 {
    margin-top: 0;
    font-size: 1.5rem;
    color: #333;
  }
  
  .delete-warning {
    color: #721c24;
    font-weight: 500;
  }
  
  .delete-error {
    background-color: #f8d7da;
    color: #721c24;
    padding: 0.75rem;
    border-radius: 4px;
    margin: 1rem 0;
    border: 1px solid #f5c6cb;
  }
  
  .delete-modal-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.5rem;
    gap: 1rem;
  }
  
  .cancel-button {
    padding: 0.6rem 1.2rem;
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .delete-modal .delete-button {
    padding: 0.6rem 1.2rem;
  }
  
  /* Password protection styles */
  .password-container {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
  }
  
  .password-title {
    color: #013A4E;
    margin-top: 0;
    text-align: center;
    font-size: 1.5rem;
  }
  
  .password-instruction {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #666;
  }
  
  .password-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .password-input {
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .password-submit {
    padding: 0.75rem;
    background-color: #013A4E;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    transition: background 0.2s;
  }
  
  .password-submit:hover {
    background-color: #025670;
  }
  
  .password-error {
    color: #721c24;
    background-color: #f8d7da;
    padding: 0.75rem;
    border-radius: 4px;
    text-align: center;
    margin-top: 1rem;
  }