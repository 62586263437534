.login-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.login-form {
    width: 40%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f7f7f7;
}

.login-form h2 {
    text-align: center;
}

.login-form .textfield {
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
}

.login-form button {
    width: 100%;
    padding: 10px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    cursor: pointer;
}