.news {
    display: flex;
    flex-direction: column;
}

#edit_button:hover {
    color: 'red'
}

.news_header {
    text-align: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 8px;
    margin: 0.5rem 1rem 0.5rem 1rem;
    min-height: 2vh;
    padding: 8px;
    /* justify-content: space-around; */
    align-items: center;
}

.news_not_selected {
    margin: 0.5rem 0rem;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 8px;
    border: 2px solid rgb(188, 188, 188);
    padding: 8px;
    align-items: center;
    text-align: center;
}

.table-heading {
    font-weight: bold;
}