.container {
    /* display: flex; */
    /* justify-content: space-around; */
    /* background-color: red; */
    margin-top: 5rem;
    padding: 0rem 2rem;
}

.reservation,
.calendar {
    width: 100%;
    /*
    display: flex;
    justify-content: space-around; */
}

.calendar_display {
    display: flex;
}

.order_summary {
    display: flex;
    box-shadow: #DA327D 0px 0px 10px;
    background: white;
    /* border: 1px solid #DA327D; */
    position: fixed;
    z-index: 999;
    width: 20%;
    top: 0;
    left: 0;
    transition: width 0.8s ease;
    /* Add transition for width property */
}

.order_summary_overview {
    width: 100%;
    transition: width 0.8s ease;
    /* Add transition for width property */
}

.calendar {
    /* padding: 1rem; */
}

.calendar-container {}

.minh-100 {
    min-height: 100vh;
}

.booking_buttons {
    display: flex;
    justify-content: end;
}


/* Styling the checkbox container */

.checkbox_field {
    position: relative;
}

.change_button:hover {
    cursor: pointer;
    color: black !important;
}


/* Styling the checkbox itself */

.checkbox_field::before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 2px solid #00B0B2;
    /* Green border */
    background-color: white;
    border-radius: 4px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}


/* Styling the check icon */

.checkbox_field::after {
    content: '✔';
    /* Check icon */
    display: block;
    width: 16px;
    height: 16px;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    /* Center the check icon horizontally */
    transform: translate(-50%, -50%);
    /* Center the check icon both horizontally and vertically */
    color: #00B0B2;
    /* Green check icon */
    opacity: 0;
    /* Hide the check icon by default */
}


/* Styling the check icon when the checkbox is checked */

.checkbox_field:checked::after {
    opacity: 1;
    /* Show the check icon when the checkbox is checked */
}

.check_button {
    display: flex;
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    /* border-bottom: 2px solid #DA327D; */
}

.scroll_div::-webkit-scrollbar {
    width: 6px;
}

.scroll_div::-webkit-scrollbar-thumb {
    background-color: #DA327D;
    border-radius: 3px;
}

.scroll_div::-webkit-scrollbar-thumb:hover {
    background-color: #b02161;
}

.scroll_div::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 3px;
}

.reservation {
    padding: 0rem 1rem 0rem 5rem;
}

.timeslot {
    display: flex;
    justify-content: center;
    padding: 0.2rem;
}

.timeslot:first-child {
    margin-top: 1rem;
}

.timeslot_nopadding {
    display: flex;
    justify-content: center;
    /* margin-bottom: 1rem; */
    min-height: 4rem;
    /* background-color: red; */
    /* margin: 0.2rem 0rem; */
    /* border-bottom: 1px solid gray; */
}

.last_timeslot {
    display: flex;
    justify-content: center;
    /* margin-bottom: 1rem; */
    min-height: 4rem;
    /* background-color: red; */
    /* margin: 0.2rem 0rem; */
    border-bottom: 0.5rem solid red;
    margin-bottom: 2rem;
}

.regular {
    width: 100%;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    min-height: 3rem;
    padding-right: 1rem;
}

.admin_timeslot {
    display: flex;
    justify-content: center;
    /* margin-bottom: 1rem; */
    min-height: 4rem;
    /* background-color: red; */
    /* margin: 0.2rem 0rem; */
    border-bottom: 1px solid gray;
}

.same_order {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    min-height: 3rem;
    /* bord */
}


/* packages */

.select_package {
    display: flex;
    width: 100% !important;
    min-height: 50vh;
    border: 1px solid gray;
    margin: 2px;
}

.select_package:hover {
    border: 5px solid gray;
}

.form_container {
    display: block;
    width: 100%;
    /* padding: 1rem; */
}

.textfield_container {
    padding: 1rem;
}

.textfield_container_desktop {
    /* padding: 1rem; */
    display: flex;
}

.textfield_container {
    padding-right: 0;
}

.textfield {
    /* padding: 1rem; */
    width: 100%;
    background-color: white;
    border: 0px solid white !important;
    box-shadow: 0xp 20px 30px 0px lightgray;
}

.linear_progress {
    width: 100%;
    user-select: none;
    margin-bottom: 3em;
    margin-top: 3rem;
}

.current_step {
    width: 100%;
    user-select: none;
    margin-bottom: 3em;
    margin-top: 3rem;
    min-height: 10vh;
}

.current_step_desc {
    max-width: 35%;
    word-break: break-word;
}

.calendar_css {
    min-height: 50vh;
    max-height: 50vh;
    overflow-y: auto;
    scrollbar-width: 'thin';
    scrollbar-color: '#DA327D white';
}

.packages_container {
    display: 'flex';
    width: '100% !important';
}

.buttons_nav {
    min-height: 3rem;
    margin-top: 30px;
}

.information_detail {
    display: flex;
    margin: 0.5rem 0rem;
}

.break_words {
    word-break: break-word;
}

@media screen and (max-width: 768px) {
    .reservation {
        padding: 0rem;
    }
    .information_detail {
        display: block;
    }
    .buttons_nav {
        margin-left: 0rem;
    }
    .packages_container {
        display: block;
    }
    .calendar_display {
        display: block;
    }
    .linear_progress {
        width: '100%';
        user-select: 'none';
    }
    .current_step {
        margin-top: 3.5rem;
        width: '100%';
        user-select: 'none';
    }
    .current_step_desc {
        max-width: 100%;
        word-break: break-word;
    }
    .container {
        /* display: flex; */
        /* justify-content: space-around; */
        /* background-color: red; */
        margin-top: 5rem;
        padding: 2rem;
    }
    .calendar_css {
        min-height: initial;
        max-height: initial;
        /* overflow-y: auto; */
        /* scrollbar-width: 'thin'; */
        /* scrollbar-color: '#DA327D white'; */
    }
    .mobile_hide {
        display: none;
    }
}