/* BlogEditor.css */

/* Split layout container */
.blog-editor-container {
  display: flex;
  /* width: 100%; */
  height: 100vh;
  margin: 1rem 10rem;
  overflow: hidden;
  /* font-family: 'Inter', sans-serif; */
  color: #333;
}

.language-dropdown-container {
  margin-right: 10px;
}

.language-select {
  min-width: 140px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.selected-language, .language-option {
  display: flex;
  align-items: center;
  padding: 5px;
}

.language-flag {
  margin-right: 8px;
  font-size: 1.2em;
}

.language-name {
  font-size: 14px;
}

/* Editor panel - 60% */
.editor-panel {
  width: 60%;
  /* padding-bottom: 10rem; */
  height: 100%;
  overflow-y: auto;
  padding: 0rem 1rem 10rem 0rem;
  background-color: #fff;
  border-right: 1px solid #e5e7eb;
}

/* Preview panel - 40% */
.preview-panel {
  width: 40%;
  height: 100%;
  overflow-y: auto;
  padding: 0 2rem 2rem;
  background-color: #f9fafb;
}

/* Make scroll independent for both panels */
.editor-panel::-webkit-scrollbar,
.preview-panel::-webkit-scrollbar {
  width: 8px;
}

.editor-panel::-webkit-scrollbar-thumb,
.preview-panel::-webkit-scrollbar-thumb {
  background-color: #d1d5db;
  border-radius: 4px;
}

.editor-panel::-webkit-scrollbar-track,
.preview-panel::-webkit-scrollbar-track {
  background-color: #f3f4f6;
}

/* Sticky editor header */
.editor-header {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 1rem 0;
  z-index: 10;
  border-bottom: 1px solid #e5e7eb;
  margin-bottom: 1.5rem;
}

.editor-title {
  font-size: 1.75rem;
  font-weight: 700;
  color: #1a1a1a;
  /* margin-bottom: 0.5rem; */
}
.editor-title h1 {
  padding: 0px;
  margin: 0px;
}

/* Sticky preview heading */
.preview-heading {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 1.5rem;
  padding: 1rem 0;
  border-bottom: 1px solid #e5e7eb;
  position: sticky;
  top: 0;
  background-color: #f9fafb;
  z-index: 10;
}

/* Action buttons (preview, schema) */
.editor-actions {
  display: flex;
  justify-content: flex-end;
}

.schema-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: #e0f2fe;
  color: #0369a1;
}

.schema-button:hover {
  background-color: #bae6fd;
}

/* Form styling */
.blog-form {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

label {
  font-weight: 500;
  color: #4b5563;
}

input[type="text"],
textarea {
  padding: 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.2s ease;
}

input[type="text"]:focus,
textarea:focus {
  border-color: #4f46e5;
  outline: none;
  box-shadow: 0 0 0 2px rgba(79, 70, 229, 0.2);
}

.error {
  border-color: #ef4444 !important;
}

.error-message {
  color: #ef4444;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

/* Section styling */
.content-sections {
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 1.25rem;
  background-color: #f9fafb;
  margin-bottom: 1.5rem;
}

.sections-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem;
}

.sections-header h3 {
  font-size: 1.125rem;
  font-weight: 600;
  color: #1f2937;
  margin: 0;
}

.content-section {
  margin-bottom: 1.5rem;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  overflow: hidden;
  background-color: white;
  transition: all 0.2s ease;
  cursor: pointer;
}

.content-section.active {
  border-color: #4f46e5;
  box-shadow: 0 0 0 2px rgba(79, 70, 229, 0.1);
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.75rem;
  background-color: #f3f4f6;
  border-bottom: 1px solid #e5e7eb;
}

.section-type {
  font-weight: 500;
  color: #4b5563;
  font-size: 0.875rem;
}

.section-controls {
  display: flex;
  gap: 0.375rem;
}

.section-control-btn {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #d1d5db;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.875rem;
  line-height: 1;
  color: #4b5563;
}

.section-control-btn:hover:not(:disabled) {
  background-color: #f3f4f6;
  border-color: #9ca3af;
}

.section-control-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.section-control-btn.remove {
  color: #ef4444;
}

.section-control-btn.remove:hover:not(:disabled) {
  background-color: #fef2f2;
  border-color: #ef4444;
}

/* React Quill styling */
.section-quill-editor {
  border: none !important;
}

.section-quill-editor .ql-container {
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
  /* font-family: 'Inter', sans-serif; */
  font-size: 1rem;
}

.section-quill-editor .ql-toolbar {
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  border-bottom: 1px solid #e5e7eb !important;
}

.image-section {
  padding: 1rem;
}

/* Add section buttons */
.add-section-controls {
  display: flex;
  gap: 0.75rem;
  margin-top: 0.75rem;
}

.add-section-btn {
  flex: 1;
  padding: 0.625rem;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px dashed #d1d5db;
  background-color: white;
  font-size: 0.875rem;
}

.add-section-btn.text {
  color: #4f46e5;
}

.add-section-btn.text:hover {
  background-color: #eef2ff;
  border-color: #4f46e5;
}

.add-section-btn.image {
  color: #059669;
}

.add-section-btn.image:hover {
  background-color: #ecfdf5;
  border-color: #059669;
}

/* Schema textarea */
.schema-textarea {
  /* font-family: monospace; */
  font-size: 0.875rem;
  background-color: #f9fafb;
  resize: vertical;
  min-height: 150px;
}

/* Button group */
.button-group {
  display: flex;
  gap: 0.75rem;
  /* margin-top: 1.5rem; */
}

.api-error-message {
  background-color: #fef2f2;
  color: #b91c1c;
  padding: 0.75rem 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  border-left: 4px solid #ef4444;
  font-size: 0.875rem;
}
/* Password protection styles */
.password-container {
  max-width: 400px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  text-align: center;
}

.password-title {
  font-size: 1.5rem;
  font-weight: 700;
  color: rgb(1, 58, 78);
  margin-bottom: 1rem;
}

.password-instruction {
  color: #4a5568;
  margin-bottom: 1.5rem;
}

.password-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.password-input {
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  font-size: 1rem;
}

.password-input:focus {
  border-color: rgb(1, 58, 78);
  outline: none;
  box-shadow: 0 0 0 2px rgba(1, 58, 78, 0.2);
}

.password-submit {
  padding: 0.75rem;
  background-color: rgb(1, 58, 78);
  color: white;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.password-submit:hover {
  background-color: rgba(1, 58, 78, 0.9);
}

.slug-hint {
  font-size: 0.8rem;
  color: #718096;
  font-weight: normal;
}

.slug-input-wrapper {
  display: flex;
  align-items: center;
}

.slug-prefix {
  padding: 0.75rem;
  background-color: #f3f4f6;
  border: 1px solid #e5e7eb;
  border-right: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #6b7280;
}

.slug-input-wrapper input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  flex: 1;
}

.post-link {
  margin-top: 1rem;
  padding-top: 0.5rem;
  border-top: 1px solid rgba(1, 58, 78, 0.2);
}

.view-post-link {
  display: inline-block;
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: rgb(1, 58, 78);
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-weight: 500;
  transition: background-color 0.2s;
}

.view-post-link:hover {
  background-color: rgba(1, 58, 78, 0.8);
}

.password-error {
  color: #e53e3e;
  margin-top: 1rem;
  font-size: 0.875rem;
}

.success-message {
  background-color: #ecfdf5;
  color: #065f46;
  padding: 0.75rem 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  border-left: 4px solid #10b981;
  font-size: 0.875rem;
}

/* Update Button Group in Header */
.editor-header .button-group {
  display: flex;
  gap: 0.75rem;
}

.editor-header .button-group button {
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
}

.draft-button,
.submit-button {
  font-size: 0.875rem;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  border: none;
  font-weight: 500;
  min-width: 10rem;
  cursor: pointer;
  transition: all 0.2s ease;
  flex: 1;
}

.draft-button {
  background-color: #f3f4f6;
  color: #4b5563;
  border: 1px solid #e5e7eb;
}

.draft-button:hover:not(:disabled) {
  background-color: #e5e7eb;
}

.submit-button {
  background-color: #4f46e5;
  color: white;
}

.submit-button:hover:not(:disabled) {
  background-color: #4338ca;
}

.draft-button:disabled,
.submit-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Preview Mode Styling */
.blog-preview {
  height: 100%;
}

.post-preview {
  line-height: 1.7;
}

.preview-post-title {
  font-size: 2rem;
  font-weight: 800;
  color: #1a1a1a;
  margin-bottom: 0.5rem;
}

.preview-post-subtitle {
  font-size: 1.25rem;
  font-weight: 400;
  color: #4b5563;
  margin-bottom: 1.5rem;
}

.preview-featured-image {
  margin-bottom: 1.5rem;
}

.preview-featured-image img {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
  border-radius: 8px;
}

.preview-post-body {
  color: #333;
}

.preview-section {
  margin-bottom: 1.5rem;
}

.preview-text-section h1 {
  font-size: 1.75rem;
  margin-bottom: 1rem;
  font-weight: 700;
}

.preview-text-section h2 {
  font-size: 1.5rem;
  margin-bottom: 0.875rem;
  font-weight: 600;
}

.preview-text-section p {
  margin-bottom: 1rem;
}

.preview-text-section ul,
.preview-text-section ol {
  margin-left: 1.5rem;
  margin-bottom: 1rem;
}

.preview-section-image {
  max-width: 100%;
  border-radius: 8px;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .blog-editor-container {
    flex-direction: column;
    height: auto;
    margin: 1rem;
  }
  
  .editor-panel,
  .preview-panel {
    width: 100%;
    height: auto;
    max-height: none;
    overflow: visible;
  }
  
  .editor-panel {
    border-right: none;
    border-bottom: 1px solid #e5e7eb;
  }
}