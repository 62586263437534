/* News.css */
.news-container {
    margin: 0 10rem;
    padding-top: 2rem;
  }
  
  .news-page-title {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 2rem;
    color: #1a202c;
    text-align: center;
    position: relative;
  }
  
  .news-page-title:after {
    content: '';
    display: block;
    width: 80px;
    height: 4px;
    background: rgb(1, 58, 78);
    margin: 1rem auto 0;
    border-radius: 2px;
  }
  
  .news-grid {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 3rem;
  }
  
  .news-card {
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    background-color: #fff;
    height: 250px;
  }
  
  .news-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1), 0 4px 6px rgba(0, 0, 0, 0.05);
  }
  
  .news-card-image {
    width: 312px;
    min-width: 312px;
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  
  .news-card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .news-card:hover .news-card-image img {
    transform: scale(1.05);
  }
  
  .news-card-image.placeholder {
    background-color: #e2e8f0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .placeholder-text {
    color: #a0aec0;
    font-size: 1.125rem;
    font-weight: 500;
  }
  
  .news-card-content {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 1.5rem;
    flex-grow: 1;
    justify-content: flex-start;
  }
  
  .news-card-title {
    font-size: 1.75rem;
    font-weight: 500;
    line-height: 1.3;
    margin-bottom: 1.5rem;
    color: rgb(1, 58, 78);
  }
  
  .news-card-title a {
    color: rgb(1, 58, 78);
    text-decoration: none;
    transition: color 0.2s ease;
  }
  
  .news-card-title a:hover {
    color: rgba(1, 58, 78, 0.8);
  }
  
  .news-card-title::after {
    content: '';
    display: block;
    width: 50px;
    height: 3px;
    background-color: rgb(1, 58, 78);
    margin-top: 0.5rem;
  }
  
  .news-card-subtitle {
    font-size: 1.125rem;
    color: #4a5568;
    margin-bottom: 1rem;
    font-weight: 500;
  }
  
  .news-card-excerpt {
    color: #4a5568;
    margin-bottom: 1rem;
    line-height: 1.5;
    font-size: 0.9rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  .news-card-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-top: auto; */
    font-size: 0.875rem;
  }
  
  .news-card-date {
    color: #718096;
  }
  /* Password protection styles */
.password-container {
    max-width: 400px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    text-align: center;
  }
  
  .password-title {
    font-size: 1.5rem;
    font-weight: 700;
    color: rgb(1, 58, 78);
    margin-bottom: 1rem;
  }
  
  .password-instruction {
    color: #4a5568;
    margin-bottom: 1.5rem;
  }
  
  .password-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .password-input {
    padding: 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .password-input:focus {
    border-color: rgb(1, 58, 78);
    outline: none;
    box-shadow: 0 0 0 2px rgba(1, 58, 78, 0.2);
  }
  
  .password-submit {
    padding: 0.75rem;
    background-color: rgb(1, 58, 78);
    color: white;
    border: none;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .password-submit:hover {
    background-color: rgba(1, 58, 78, 0.9);
  }
  
  .password-error {
    color: #e53e3e;
    margin-top: 1rem;
    font-size: 0.875rem;
  }
  
  .news-card-read-more {
    background-color: rgb(1, 58, 78);
    color: white;
    font-weight: 600;
    text-decoration: none;
    min-width: 15rem;
    text-align: center;
    padding: 0.5rem 1rem;
    /* background-color: #f8f9fa; */
    border-radius: 20px;
    transition: background-color 0.2s ease, color 0.2s ease;
  }
  
  .news-card-read-more:hover {
    background-color: rgba(1, 58, 78, 0.8);
  }
  
  .news-pagination {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
  }
  
  .pagination-button {
    min-width: 40px;
    height: 40px;
    padding: 0 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e2e8f0;
    background-color: #fff;
    color: #4a5568;
    font-size: 0.875rem;
    font-weight: 500;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .pagination-button:hover:not(:disabled) {
    background-color: #f7fafc;
    color: rgb(1, 58, 78);
    border-color: rgb(1, 58, 78);
  }
  
  .pagination-button.active {
    background-color: rgb(1, 58, 78);
    color: white;
    border-color: rgb(1, 58, 78);
  }
  
  .pagination-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .news-page-info {
    text-align: center;
    color: #718096;
    font-size: 0.875rem;
  }
  
  .news-loading,
  .news-error,
  .news-empty {
    text-align: center;
    padding: 3rem 1rem;
    font-size: 1.125rem;
    color: #4a5568;
  }
  
  .news-error {
    color: #e53e3e;
  }
  
  @media (max-width: 768px) {
    .news-container {
      margin: 0 2rem;
      padding-top: 2rem;
    }
    
    .news-card {
      flex-direction: column;
      height: auto;
    }
    
    .news-card-image {
      width: 100%;
      height: 200px;
    }
    
    .news-page-title {
      font-size: 2rem;
    }
    
    .pagination-button {
      min-width: 36px;
      height: 36px;
      padding: 0 0.5rem;
    }
  }
  
  @media (max-width: 480px) {
    .news-container {
      margin: 0 1rem;
      padding: 1.5rem 1rem;
    }
    
    .news-card-image {
      height: 180px;
    }
    
    .news-card-title {
      font-size: 1.25rem;
    }
    
    .news-card-subtitle {
      font-size: 1rem;
    }
  }